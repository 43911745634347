import { FC } from 'react';
import './ConfirmationDialog.scss';
import { Button, DialogTrigger } from '@fluentui/react-components';
import GenericDialogContainer, { ITriggerProps } from '../GenericDialogContainer/GenericDialogContainer';


export interface ConfirmationBodyText {
	confirmationText: string;
}

const ConfirmationBody: FC<ConfirmationBodyText> = (props) => (
	<div className="ConfirmationBody" data-testid="ConfirmationBody">
	  {props.confirmationText}?
	</div>
);

export interface ConfirmationTriggers {
	onYesClick: any,
	onNoClick: any
}

const ConfirmationActionButtons: FC<ConfirmationTriggers> = (props) => (
	<>
		<DialogTrigger disableButtonEnhancement>
			<Button appearance="secondary" onClick={() => props.onNoClick()}>No</Button>
		</DialogTrigger>
		<Button appearance="primary" onClick={() => props.onYesClick()}>Yes</Button>
	</>
)

export interface ConfirmationDialogProps {
	confirmationBody: ConfirmationBodyText;
	confirmationTriggers: ConfirmationTriggers;
	triggerProps: ITriggerProps;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = (props) => {
	const ConfirmationBodyCont = () => (
		<ConfirmationBody confirmationText={props.confirmationBody.confirmationText}/>
	)
	const ConfirmationActionButtonCont = () => (
		<ConfirmationActionButtons
			onNoClick={() => props.confirmationTriggers.onNoClick()}
			onYesClick={() => props.confirmationTriggers.onYesClick()}
		/>
	)
	return (
		<GenericDialogContainer TemplateBody={ConfirmationBodyCont}
			TemplateActions={ConfirmationActionButtonCont}
			dialogName='Confirmation Modal'
			TriggerProps={props.triggerProps}
		/>
	);
}

export default ConfirmationDialog;
