import { FC, useEffect, useState } from 'react';
import './RecordsDialog.scss';
import { useDynamicFieldConvertion } from '../../hooks/UseDynamicFieldConvertion';
import DynamicRecordForm from '../DynamicRecordForm/DynamicRecordForm';
import { Button, Field, Input, Textarea } from '@fluentui/react-components';

interface RecordsDialogProps {
  	data: any,
	recordId: number,
	errorsType: boolean,
	onCancel: any,
	onSave: any,
	onInvalidate: any,
	onValidate: any,
}

const RecordsDialog: FC<RecordsDialogProps> = (props) => {

  	const {fields, dynamicConvertion, changeFieldsValue} = useDynamicFieldConvertion()
	const [observations, setObservations] = useState<string>("")

	useEffect(() => {
		dynamicConvertion(props.data)
	}, [])

	function ActionsButtons() {
		if(props.errorsType){
			return <Button appearance='primary' onClick={() => props.onSave(fields)}>Save</Button>
		}
		else{
			return <>
				<Button appearance='primary' onClick={() => props.onInvalidate(observations)}>Invalidate</Button>
				<Button appearance='primary' onClick={() => props.onValidate(observations)}>Validate</Button>
			</>
		}
	}

	const onCancel = (event: any) => {
		dynamicConvertion(props.data)
		props.onCancel(event)
	}

	return (
		<>
			<div className='FormFields'>
				<DynamicRecordForm fields={fields} changeFieldsValue={changeFieldsValue} onCancel={props.onCancel} onSave={props.onSave}/>
				{!props.errorsType && <Field label="Observations">
					<Textarea resize='both' size='large' value={observations} onChange={(event: any, data: any) => setObservations(data.value)} />
				</Field>}
			</div>
			<div className='ButtonsContainer'>
				<Button appearance='secondary' onClick={onCancel}>Cancel</Button>
				<ActionsButtons/>
			</div>
		</>
	)
};

export default RecordsDialog;
