import React, { FC, useState } from 'react';
import './TodoRecords.scss';
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell, TableCellLayout, Button, Dropdown, SelectionEvents, OptionOnSelectData, Input, TableSelectionCell, useFocusableGroup, InputOnChangeData, TableColumnDefinition, createTableColumn, TableColumnId, useTableFeatures, useTableSort, TableColumnSizingOptions, useTableColumnSizing_unstable } from '@fluentui/react-components';

import { format } from 'date-fns';
import {
	EditRegular,
} from "@fluentui/react-icons";
import { Pagination } from '../Pagination/Pagination';
import RowCounterSelector from '../RowCounterSelector/RowCounterSelector';
import { CANCEL_ACTION, ERROR_RECORDS, ERROR_TYPE, INVALIDATE_ACTION, PAGINTATION_SIBLING_COUNT, ROWS_PER_PAGE, SUBMIT_ACTION, VALIDATE_ACTION, WAITING_TYPE } from '../../utils/constants';
import { Link } from 'react-router-dom';
import GenericDialog from '../GenericDialog/GenericDialog';
import RecordsDialog from '../RecordsDialog/RecordsDialog';
import RecordService from '../../services/RecordService';
import { dynamicObjectToFieldConvertor } from '../../utils/helpers';
import { IFieldDetail } from '../../interfaces/IFIeldDetail';
import { RecordsDataSelectable } from '../../types/RecordsDataSelectable';
import { IRecordsData } from '../../interfaces/IRecordsData';
import ConfirmationDialog, { ConfirmationBodyText, ConfirmationTriggers } from '../ConfirmationDialog/ConfirmationDialog';
import { ITriggerProps } from '../GenericDialogContainer/GenericDialogContainer';

const { parseISO } = require("date-fns");
interface TodoRecordsProps {
	data: Array<IRecordsData>
	currentPage: number;
	allRecordCount?: number;
	rowPerPage?: number;
	onPageChange: any;
	onAmountPageChange: any;
	selectedTab: string;
}

const tableColumns: TableColumnDefinition<RecordsDataSelectable>[] = [
	createTableColumn<RecordsDataSelectable>({
		columnId: "job_id",
		compare: (a, b) => {
			return a.job_id - b.job_id
		},
		renderHeaderCell: () => <>Job #Id</>
	}),
	createTableColumn<RecordsDataSelectable>({
		columnId: "record_id",
		compare: (a, b) => {
			return a.id - b.id
		},
		renderHeaderCell: () => <>Record #Id</>
	}),
	createTableColumn<RecordsDataSelectable>({
		columnId: "created_on",
		compare: (a, b) => {
			return a.created_on.localeCompare(b.created_on)
		},
		renderHeaderCell: () => <>Created On</>
	}),
	createTableColumn<RecordsDataSelectable>({
		columnId: "processes",
		compare: (a,b) => {
			return a.process.localeCompare(b.process)
		},
		renderHeaderCell: () => <>Processes</>
	}),
	createTableColumn<RecordsDataSelectable>({
		columnId: "subprocesses",
		compare: (a, b) => {
			return a.subprocess.localeCompare(b.subprocess)
		},
		renderHeaderCell: () => <>Subprocesses</>
	}),
	createTableColumn<RecordsDataSelectable>({
		columnId: "input_data",
		renderHeaderCell: () => <>Input Data</>
	}),
	createTableColumn<RecordsDataSelectable>({
		columnId: "output_data",
		renderHeaderCell: () => <>Output Data</>
	}),
	createTableColumn<RecordsDataSelectable>({
		columnId: "actions",
		renderHeaderCell: () => <>Actions</>
	})
]

const TodoRecords: FC<TodoRecordsProps> = (props) => {
	const STYLE_SMALL = "ListStyleSmall"
	const STYLE_BIG = "ListStyleBig"
	const [records, setRecords] = useState<RecordsDataSelectable[]>(
		props.data.map((item: IRecordsData) => ({...item, selected: false}))
	)
	const [collapsedRecords, setCollapsedRecords] = useState<{id: number, collapsed: boolean}[]>(
		records.map((record: RecordsDataSelectable) => ({id: record.id, collapsed: true}))
	)

	const [sortState, setSortState] = React.useState<{
		sortDirection: "ascending" | "descending";
		sortColumn: TableColumnId | undefined;
	  }>({
		sortDirection: "ascending" as const,
		sortColumn: "file",
	});

	const [columnSizingOptions] = React.useState<TableColumnSizingOptions>({
		job_id: {
			idealWidth: 100,
			minWidth: 100,
		},
		record_id: {
			idealWidth: 100,
			minWidth: 100,
		},
		created_on: {
			minWidth: 150
		},
		processes: {
			minWidth: 200,
			idealWidth: 300,
		},
		subprocesses: {
		  	minWidth: 200,
			  idealWidth: 300
		},
		input_data: {
			minWidth: 110,
			idealWidth: 300,
		},
		output_data: {
			minWidth: 110,
			idealWidth: 250,

		},
		actions: {
			idealWidth: 50,
		}
	});

	const {
		getRows,
		sort: { getSortDirection, toggleColumnSort, sort },
		columnSizing_unstable,
		tableRef
	} = useTableFeatures(
		{
			columns: tableColumns,
			items: records,
		},
		[
		  useTableSort({
			sortState,
			onSortChange: (e, nextSortState) => setSortState(nextSortState),
		  }),
		  useTableColumnSizing_unstable({columnSizingOptions})
		]
	);

	const headerSortProps = (columnId: TableColumnId) => ({
		onClick: (e: React.MouseEvent) => toggleColumnSort(e, columnId),
		sortDirection: getSortDirection(columnId),
	});

	const [allCheck, setAllCheck] = useState<boolean>(false)
	const [observations, setObservations] = useState<{[key: number]: string}>({})

	const errorTable = props.selectedTab === ERROR_RECORDS
	const showStatus = props.selectedTab === ERROR_RECORDS? ERROR_TYPE: WAITING_TYPE

	const availableColumns = errorTable?
		tableColumns.filter(c => c.columnId !== 'obs'):
		tableColumns.filter(c => c.columnId !== 'output_data')

	const triggerRecordAction = (action: string, selectedRecordIds: number[], obs: any) => {
		RecordService
			.validateInvalidateRecords(action, selectedRecordIds, obs)
			.then(result => {
				props.onPageChange(props.currentPage, props.selectedTab)
			})
	}

	const onOptionSelected = (event: SelectionEvents, data: OptionOnSelectData) => {
		props.onAmountPageChange(data.optionValue)
	}

	const onRowSelected = (id: number) => {
		const recs = records.map((record: RecordsDataSelectable) =>
			record.id === id ? ({
				...record, selected: !record.selected
			})
			: record
		)
		setRecords(recs)
		setAllCheck(recs.filter(r => r.selected).length === recs.length)
	}

	const onHeaderSelected = () => {
		const changedStatus = !allCheck;
		setRecords(
			records.map(record => ({...record, selected: changedStatus}))
		)
		setAllCheck(changedStatus)
	}

	const anySelectedRecord = () => records.filter(r => r.selected).length

	const convertDynamicData = (record: RecordsDataSelectable) => {
		const fieldList = dynamicObjectToFieldConvertor(record.data)
		const collapseRow = collapsedRecords.find(c => c.id === record.id)
		return <div className='DynamicInputData'>
			<ul className={collapseRow?.collapsed? STYLE_SMALL: STYLE_BIG}>
				{fieldList.map((field: IFieldDetail) =>
					<li key={field.label}><b>{field.label}</b>: {field.value}</li>
				)}
			</ul>
			<p className='MoreLess' onClick={(event: any) => changeInputStyle(event, record.id)}>{collapseRow?.collapsed? "More": "Less"}...</p>
		</div>
	}

	const changeInputStyle = (event: any, recordId: number) => {
		event.stopPropagation()
		const newCollapseList = collapsedRecords.map((collapsed: any) =>
			collapsed.id === recordId?
				({id: recordId, collapsed: !collapsed.collapsed}): collapsed
		)
		setCollapsedRecords(newCollapseList)
	}

	const onRecordAction = (action: string): void => {
		const selectedRecordIds = records
			.filter(r => r.selected)
			.map((record: RecordsDataSelectable) => record.id)

		triggerRecordAction(action, selectedRecordIds, observations)
	}

	const onValidationFromDialog = (recordId: number, action: string, obs: string) => {
		observations[recordId] = obs
		setObservations(observations)
		triggerRecordAction(action, [recordId], observations)
	}

	const onCancelFromDialog = () => {

	}

	const onSave = (fields: any, recordId: number) => {
		let fieldsToSave: any = {}
		fields.forEach((field: any) => {
			fieldsToSave[field.label] = field.value
		})
		RecordService.editRecordsData(recordId, fieldsToSave).then((result: any) => {
			props.onPageChange(props.currentPage)
		})
	}

	function createDateWithFormat(dateString: string) {
		const date = parseISO(dateString)
		return format(date, "dd-MM-yyyy, HH: mm")
	}

	function GenericDialogButton({action, actionText, isDisabled, appearance}: any){
		const configurationBody: ConfirmationBodyText = {
			confirmationText: `Are you sure you want to execute the ${actionText} action to the selected records?`
		}
		const confirmationTrigger: ConfirmationTriggers = {
			onNoClick: () => {},
			onYesClick: () => onRecordAction(action)
		}
		const triggerProps: ITriggerProps = {
			appearance,
			icon: null,
			isDisabled,
			text: actionText
		}
		return <ConfirmationDialog confirmationBody={configurationBody} confirmationTriggers={confirmationTrigger} triggerProps={triggerProps}/>
	}

	function ButtonsByView() {
		if(errorTable){
			return (
				<div className='ButtonActions'>
					<GenericDialogButton action="cancel" actionText="Cancel" isDisabled={!anySelectedRecord()}/>
					<GenericDialogButton appearance="primary" action="submit" actionText="Submit" isDisabled={!anySelectedRecord()} />
				</div>
			)
		}
		return (
			<div className='ButtonActions'>
				<GenericDialogButton action="cancel" actionText="Cancel" isDisabled={!anySelectedRecord()} onClick={() => onRecordAction(CANCEL_ACTION)}/>
				<GenericDialogButton appearance='primary' action="invalidate" actionText="Invalidate" isDisabled={!anySelectedRecord()} onClick={() => onRecordAction(INVALIDATE_ACTION)}/>
				<GenericDialogButton appearance='primary' action="validate" actionText="Validate" isDisabled={!anySelectedRecord()} onClick={() => onRecordAction(VALIDATE_ACTION)}/>
			</div>
		)
	}

	const rows = sort(getRows());

	return (
		<div className='TodoRecordContainer'>
			<div className='TableActions'>
				<RowCounterSelector onCountSelected={onOptionSelected} rowCount={props.rowPerPage?? ROWS_PER_PAGE[0]}/>
				<ButtonsByView />
			</div>
			<Table sortable arial-label="Default table" {...columnSizing_unstable.getTableProps()}>
				<TableHeader>
					<TableRow className='TableHeader' >
						<TableSelectionCell
							onClick={onHeaderSelected}
							className='HeaderCell'
							checked={allCheck}
							checkboxIndicator={{ "aria-label": "Select all rows " }}
						/>
						{availableColumns.map((column) => {
							return (
								<TableHeaderCell {...headerSortProps(column.columnId)}
								 	className='HeaderCell'
									key={column.columnId}
									{...columnSizing_unstable.getTableHeaderCellProps(
										column.columnId
									)}>
										{column.renderHeaderCell()}
								</TableHeaderCell>
							)
						})}
					</TableRow>
				</TableHeader>
				<TableBody>
					{rows.map((item) => (
						<TableRow key={item.item.id} >
							<TableSelectionCell
								onClick={() => onRowSelected(item.item.id)}
								checked={item.item.selected}
								checkboxIndicator={{ "aria-label": "Select all rows " }}
							/>
							<TableCell>
								<Link className='LinkStyle' to={`/${item.item.job_id}/${showStatus}`}>{item.item.job_id}</Link>
							</TableCell>
							<TableCell>
								{item.item.id}
							</TableCell>
							<TableCell>
								<TableCellLayout>
									{createDateWithFormat(item.item.created_on)}
								</TableCellLayout>
							</TableCell>
							<TableCell>
								<TableCellLayout truncate>
									{item.item.process}
								</TableCellLayout>
							</TableCell>
							<TableCell>
								<TableCellLayout truncate>
									{item.item.subprocess}
								</TableCellLayout>
							</TableCell>
							<TableCell>
								<TableCellLayout className='TableCellLayout'>
									{convertDynamicData(item.item)}
								</TableCellLayout>
							</TableCell>
							{errorTable && <TableCell></TableCell>}
							<TableCell>
								<TableCellLayout >
									<GenericDialog TemplateComponent={RecordsDialog}
										TemplateButtonIcon={EditRegular}
										TemplateButtonText=''
										TemplateButtonProps={null}
										templateProps={
											{
												data: item.item.data,
												errorsType: errorTable,
												onSave: (fields: any) => onSave(fields, item.item.id),
												onValidate: (observations: string) => onValidationFromDialog(item.item.id, VALIDATE_ACTION, observations),
												onInvalidate: (observations: string) => onValidationFromDialog(item.item.id, INVALIDATE_ACTION, observations),
												onCancel: onCancelFromDialog
											}
										}
										dialogName='Records Edit'
									/>
								</TableCellLayout>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Pagination
				className="pagination-bar"
				siblingCount={PAGINTATION_SIBLING_COUNT}
				totalCount={props.allRecordCount?? 0}
				rowsPerPage={props.rowPerPage ?? ROWS_PER_PAGE[0]}
				onPageChange={page => props.onPageChange(page)}
				currentPage={props.currentPage}
			/>
		</div>
	)
};

export default TodoRecords;
