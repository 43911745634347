import { ChangeEvent, FC } from 'react';
import './DynamicRecordForm.scss';
import { Button, Field, Input, InputOnChangeData, Textarea } from '@fluentui/react-components';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { IFieldDetail } from '../../interfaces/IFIeldDetail';

interface DynamicRecordFormProps {
	fields: IFieldDetail[];
	changeFieldsValue: any;
	onCancel: any;
	onSave: any;
}

const DynamicRecordForm: FC<DynamicRecordFormProps> = (props) => {

	const createField = (key: string, type: string, value: string) => {
		const fieldProps: any = {
			size: 'large',
			value: value ?? "",
			key
		}
		switch (type) {
			case 'date':
				return <DatePicker {...fieldProps} onChange={(ev: any, data: any) => onFieldValueChange(ev, data, key)}></DatePicker>
			case 'textarea':
				return <Textarea {...fieldProps} resize='both' onChange={(ev: any, data: any) => onFieldValueChange(ev, data, key)}/>
			default:
				return <Input {...fieldProps} onChange={(ev: any, data: any) => onFieldValueChange(ev, data, key)}></Input>
		}
	}

	const onFieldValueChange = (ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData, key: string) => {
		props.changeFieldsValue(key, data.value)
	}

	return (
		<div className="DynamicRecordForm" data-testid="DynamicRecordForm">
			<form action="" className='DynamicForm'>
				{props.fields.map((field) => {
					return (
						<Field key={field.label} label={field.label}>
							{createField(field.label, field.type, field.value)}
						</Field>
					)
				})}

			</form>
		</div>
	)
};

export default DynamicRecordForm;
