import { FC } from 'react';
import './RowCounterSelector.scss';
import { Dropdown, Option } from '@fluentui/react-components';
import { ROWS_PER_PAGE } from '../../utils/constants';

interface RowCounterSelectorProps {
	rowCount: number;
	onCountSelected: any
}

const RowCounterSelector: FC<RowCounterSelectorProps> = (props) => {
	const options = [...ROWS_PER_PAGE];

	return (
		<div className="RowCounterSelector" data-testid="RowCounterSelector">
			<Dropdown
				className='Dropdown'
				onOptionSelect={props.onCountSelected}
				value={props.rowCount.toString()}
				{...props}
			>
				{options.map((option) => (
					<Option key={option}>
						{option.toString()}
					</Option>
				))}
			</Dropdown>
		</div>
	)
};

export default RowCounterSelector;
