import { IJob } from "../interfaces/IJob";
import { IRecord } from "../interfaces/IRecord";

export const JOBS_DATA: Array<any> = [
    {

        "created_on": "Tue, 11 Jul 2023 03:00:00 GMT",
        "error_records": 0,
        "id": 110022,
        "process": "Q02_Checkout",
        "process_id": 170,
        "status": "ERROR",
        "subprocess": "Q02_01_Extracao_Matriculas",
        "subprocess_id": 172
    },
    {

        "created_on": "Sat, 01 Jul 2023 03:00:01 GMT",
        "error_records": 0,
        "id": 107933,
        "process": "Q02_Checkout",
        "process_id": 170,
        "status": "ERROR",
        "subprocess": "Q02_01_Extracao_Matriculas",
        "subprocess_id": 172
    },
    {

        "created_on": "Thu, 16 Feb 2023 03:33:10 GMT",
        "error_records": 10,
        "id": 107558,
        "process": "Q02_Checkout",
        "process_id": 170,
        "status": "ERROR",
        "subprocess": "Q02_02_Alteracao_Emails_Telefones",
        "subprocess_id": 204
    },
    {

        "created_on": "Thu, 16 Feb 2023 03:30:11 GMT",
        "error_records": 10,
        "id": 107557,
        "process": "Q02_Checkout",
        "process_id": 170,
        "status": "ERROR",
        "subprocess": "Q02_02_Alteracao_Emails_Telefones",
        "subprocess_id": 204
    },
    {

        "created_on": "Thu, 16 Feb 2023 03:00:01 GMT",
        "error_records": 1,
        "id": 107547,
        "process": "Q01_Checkout_Renault_Entreposto",
        "process_id": 181,
        "status": "ERROR",
        "subprocess": "Q01_01_Extracao_Matriculas",
        "subprocess_id": 183
    },
    {

        "created_on": "Thu, 16 Feb 2023 03:00:01 GMT",
        "error_records": 0,
        "id": 107544,
        "process": "Q01_Checkout_Renault_Entreposto",
        "process_id": 181,
        "status": "ERROR",
        "subprocess": "Q01_01_Extracao_Matriculas",
        "subprocess_id": 183
    },
    {

        "created_on": "Thu, 16 Feb 2023 03:00:01 GMT",
        "error_records": 0,
        "id": 107543,
        "process": "Q01_Checkout_Renault_Entreposto",
        "process_id": 181,
        "status": "ERROR",
        "subprocess": "Q01_01_Extracao_Matriculas",
        "subprocess_id": 183
    },
    {

        "created_on": "Wed, 15 Feb 2023 20:30:35 GMT",
        "error_records": 3,
        "id": 107448,
        "process": "C9 - Lançamento de Faturas JAPRAC",
        "process_id": 312,
        "status": "ERROR",
        "subprocess": "C9 - IPortal",
        "subprocess_id": 301
    },
    {

        "created_on": "Wed, 15 Feb 2023 20:16:25 GMT",
        "error_records": 7,
        "id": 107447,
        "process": "C9 - Lançamento de Faturas JAPRAC",
        "process_id": 312,
        "status": "ERROR",
        "subprocess": "C9 - Lançamento de Faturas SAP",
        "subprocess_id": 317
    },
    {

        "created_on": "Wed, 15 Feb 2023 20:09:45 GMT",
        "error_records": 7,
        "id": 107443,
        "process": "C7 - Faturação Nissan",
        "process_id": 7,
        "status": "ERROR",
        "subprocess": "FAT_NISSAN 1 - Outras Faturas",
        "subprocess_id": 11
    },
    {

        "created_on": "Wed, 15 Feb 2023 20:00:02 GMT",
        "error_records": 1,
        "id": 107432,
        "process": "C9_0 - Validacao PO",
        "process_id": 330,
        "status": "ERROR",
        "subprocess": "C9_0_Validacao PO",
        "subprocess_id": 332
    },
    {

        "created_on": "Wed, 15 Feb 2023 20:00:02 GMT",
        "error_records": 1,
        "id": 107428,
        "process": "C9_0 - Validacao PO",
        "process_id": 330,
        "status": "ERROR",
        "subprocess": "C9_0_Validacao PO",
        "subprocess_id": 332
    },
    {

        "created_on": "Wed, 15 Feb 2023 17:00:01 GMT",
        "error_records": 1,
        "id": 107373,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_01-CarregarTarefasPricing",
        "subprocess_id": 278
    },
    {

        "created_on": "Tue, 14 Feb 2023 14:50:34 GMT",
        "error_records": 1,
        "id": 107244,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 10:23:57 GMT",
        "error_records": 33,
        "id": 107205,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 10:23:57 GMT",
        "error_records": 1,
        "id": 107204,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 10:23:56 GMT",
        "error_records": 1,
        "id": 107201,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 08:22:03 GMT",
        "error_records": 53,
        "id": 107167,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 05:19:36 GMT",
        "error_records": 58,
        "id": 107141,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 04:20:43 GMT",
        "error_records": 35,
        "id": 107130,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 04:20:43 GMT",
        "error_records": 1,
        "id": 107129,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 04:09:30 GMT",
        "error_records": 1,
        "id": 107126,
        "process": "ACT_04 - Pricing Sixt",
        "process_id": 147,
        "status": "ERROR",
        "subprocess": "ACT_04_02 - ProcessaFicheirosPricingSixt",
        "subprocess_id": 239
    },
    {

        "created_on": "Tue, 14 Feb 2023 04:06:30 GMT",
        "error_records": 1,
        "id": 107125,
        "process": "ACT_04 - Pricing Sixt",
        "process_id": 147,
        "status": "ERROR",
        "subprocess": "ACT_04_02 - ProcessaFicheirosPricingSixt",
        "subprocess_id": 239
    },
    {

        "created_on": "Tue, 14 Feb 2023 03:52:38 GMT",
        "error_records": 2,
        "id": 107119,
        "process": "ACT_04 - Pricing Sixt",
        "process_id": 147,
        "status": "ERROR",
        "subprocess": "ACT_04_02 - ProcessaFicheirosPricingSixt",
        "subprocess_id": 239
    },
    {

        "created_on": "Tue, 14 Feb 2023 03:49:30 GMT",
        "error_records": 2,
        "id": 107118,
        "process": "ACT_04 - Pricing Sixt",
        "process_id": 147,
        "status": "ERROR",
        "subprocess": "ACT_04_02 - ProcessaFicheirosPricingSixt",
        "subprocess_id": 239
    },
    {

        "created_on": "Tue, 14 Feb 2023 03:15:05 GMT",
        "error_records": 32,
        "id": 107115,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 03:15:04 GMT",
        "error_records": 27,
        "id": 107111,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 03:15:04 GMT",
        "error_records": 1,
        "id": 107110,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 03:02:27 GMT",
        "error_records": 7,
        "id": 107108,
        "process": "Q02_Checkout",
        "process_id": 170,
        "status": "ERROR",
        "subprocess": "Q02_02_Alteracao_Emails_Telefones",
        "subprocess_id": 204
    },
    {

        "created_on": "Tue, 14 Feb 2023 02:59:40 GMT",
        "error_records": 2,
        "id": 107099,
        "process": "ACT_04 - Pricing Sixt",
        "process_id": 147,
        "status": "ERROR",
        "subprocess": "ACT_04_02 - ProcessaFicheirosPricingSixt",
        "subprocess_id": 239
    },
    {

        "created_on": "Tue, 14 Feb 2023 02:06:12 GMT",
        "error_records": 1,
        "id": 107093,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 01:20:05 GMT",
        "error_records": 70,
        "id": 107086,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Tue, 14 Feb 2023 00:42:23 GMT",
        "error_records": 1,
        "id": 107073,
        "process": "ACT_05 - Criação Dados Mestre - Modelle - BringVehicleInFleet",
        "process_id": 224,
        "status": "ERROR",
        "subprocess": "ACT_05_03 - Bring Vehicle InFleet",
        "subprocess_id": 230
    },
    {

        "created_on": "Tue, 14 Feb 2023 00:22:20 GMT",
        "error_records": 38,
        "id": 107072,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Mon, 13 Feb 2023 22:15:34 GMT",
        "error_records": 2,
        "id": 107055,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Mon, 13 Feb 2023 21:18:03 GMT",
        "error_records": 54,
        "id": 107048,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Mon, 13 Feb 2023 21:18:03 GMT",
        "error_records": 10,
        "id": 107046,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Mon, 13 Feb 2023 20:25:07 GMT",
        "error_records": 48,
        "id": 107038,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Mon, 13 Feb 2023 20:00:01 GMT",
        "error_records": 1,
        "id": 107032,
        "process": "C9_0 - Validacao PO",
        "process_id": 330,
        "status": "ERROR",
        "subprocess": "C9_0_Validacao PO",
        "subprocess_id": 332
    },
    {

        "created_on": "Mon, 13 Feb 2023 19:14:09 GMT",
        "error_records": 36,
        "id": 107025,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Mon, 13 Feb 2023 11:34:01 GMT",
        "error_records": 1,
        "id": 106955,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Mon, 13 Feb 2023 09:56:44 GMT",
        "error_records": 1,
        "id": 106921,
        "process": "ACT_03 - Despreparação - Entrega Viaturas",
        "process_id": 214,
        "status": "ERROR",
        "subprocess": "ACT_03_05 - Entrega_Viaturas_Sap",
        "subprocess_id": 247
    },
    {

        "created_on": "Mon, 13 Feb 2023 03:23:17 GMT",
        "error_records": 1,
        "id": 106856,
        "process": "Q02_Checkout",
        "process_id": 170,
        "status": "ERROR",
        "subprocess": "Q02_04_Reposicao_Emails_Telefones",
        "subprocess_id": 256
    },
    {

        "created_on": "Mon, 13 Feb 2023 03:02:15 GMT",
        "error_records": 9,
        "id": 106848,
        "process": "Q02_Checkout",
        "process_id": 170,
        "status": "ERROR",
        "subprocess": "Q02_02_Alteracao_Emails_Telefones",
        "subprocess_id": 204
    },
    {

        "created_on": "Mon, 13 Feb 2023 03:00:01 GMT",
        "error_records": 1,
        "id": 106844,
        "process": "Q01_Checkout_Renault_Entreposto",
        "process_id": 181,
        "status": "ERROR",
        "subprocess": "Q01_01_Extracao_Matriculas",
        "subprocess_id": 183
    },
    {

        "created_on": "Mon, 13 Feb 2023 00:51:01 GMT",
        "error_records": 3,
        "id": 106825,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Sun, 12 Feb 2023 22:06:00 GMT",
        "error_records": 30,
        "id": 106808,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Sun, 12 Feb 2023 20:00:00 GMT",
        "error_records": 1,
        "id": 106792,
        "process": "C9_0 - Validacao PO",
        "process_id": 330,
        "status": "ERROR",
        "subprocess": "C9_0_Validacao PO",
        "subprocess_id": 332
    },
    {

        "created_on": "Sun, 12 Feb 2023 19:59:18 GMT",
        "error_records": 1,
        "id": 106789,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Sun, 12 Feb 2023 19:59:18 GMT",
        "error_records": 67,
        "id": 106788,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Sun, 12 Feb 2023 18:51:18 GMT",
        "error_records": 3,
        "id": 106778,
        "process": "PRC01-Pricing Sixt",
        "process_id": 276,
        "status": "ERROR",
        "subprocess": "PRC01_02-ProcessaTarefasPricing",
        "subprocess_id": 280
    },
    {

        "created_on": "Sun, 12 Feb 2023 18:00:00 GMT",
        "error_records": 1,
        "id": 106774,
        "process": "ACT_05 - Criação Dados Mestre - Modelle - BringVehicleInFleet",
        "process_id": 224,
        "status": "ERROR",
        "subprocess": "ACT_05_01 - Criação Dados Mestres_SAP",
        "subprocess_id": 226
    },
    {

        "created_on": "Sun, 12 Feb 2023 14:00:01 GMT",
        "error_records": 1,
        "id": 106740,
        "process": "ACT_08_01 - Processamento de Notas de Débito (Cobra)",
        "process_id": 125,
        "status": "ERROR",
        "subprocess": "Processamento de Notas de Débito (Cobra)",
        "subprocess_id": 127
    },
    {

      "created_on": "Sun, 12 Feb 2023 13:47:50 GMT",
      "error_records": 13,
      "id": 106733,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_03 - Despreparacao_Viaturas_IW22",
      "subprocess_id": 220
    },
    {

      "created_on": "Sun, 12 Feb 2023 13:38:45 GMT",
      "error_records": 1,
      "id": 106731,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_05 - Entrega_Viaturas_Sap",
      "subprocess_id": 247
    },
    {

      "created_on": "Sun, 12 Feb 2023 13:16:39 GMT",
      "error_records": 13,
      "id": 106729,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_03 - Despreparacao_Viaturas_IW22",
      "subprocess_id": 220
    },
    {

      "created_on": "Sun, 12 Feb 2023 13:04:45 GMT",
      "error_records": 1,
      "id": 106715,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_05 - Entrega_Viaturas_Sap",
      "subprocess_id": 247
    },
    {

      "created_on": "Sun, 12 Feb 2023 12:40:37 GMT",
      "error_records": 13,
      "id": 106708,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_03 - Despreparacao_Viaturas_IW22",
      "subprocess_id": 220
    },
    {

      "created_on": "Sun, 12 Feb 2023 12:31:44 GMT",
      "error_records": 1,
      "id": 106706,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_05 - Entrega_Viaturas_Sap",
      "subprocess_id": 247
    },
    {

      "created_on": "Sun, 12 Feb 2023 12:30:31 GMT",
      "error_records": 22,
      "id": 106703,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 12:08:52 GMT",
      "error_records": 13,
      "id": 106697,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_03 - Despreparacao_Viaturas_IW22",
      "subprocess_id": 220
    },
    {

      "created_on": "Sun, 12 Feb 2023 12:00:01 GMT",
      "error_records": 1,
      "id": 106683,
      "process": "Veículos de Serviço (Amortização)",
      "process_id": 93,
      "status": "WAITING",
      "subprocess": "Extração Query SCPC",
      "subprocess_id": 95
    },
    {

      "created_on": "Sun, 12 Feb 2023 11:56:44 GMT",
      "error_records": 1,
      "id": 106680,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_05 - Entrega_Viaturas_Sap",
      "subprocess_id": 247
    },
    {

      "created_on": "Sun, 12 Feb 2023 11:34:14 GMT",
      "error_records": 13,
      "id": 106678,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_03 - Despreparacao_Viaturas_IW22",
      "subprocess_id": 220
    },
    {

      "created_on": "Sun, 12 Feb 2023 11:23:44 GMT",
      "error_records": 1,
      "id": 106670,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_05 - Entrega_Viaturas_Sap",
      "subprocess_id": 247
    },
    {

      "created_on": "Sun, 12 Feb 2023 11:15:52 GMT",
      "error_records": 1,
      "id": 106669,
      "process": "ACT_05 - Criação Dados Mestre - Modelle - BringVehicleInFleet",
      "process_id": 224,
      "status": "WAITING",
      "subprocess": "ACT_05_03 - Bring Vehicle InFleet",
      "subprocess_id": 230
    },
    {

      "created_on": "Sun, 12 Feb 2023 09:46:09 GMT",
      "error_records": 13,
      "id": 106647,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_03 - Despreparacao_Viaturas_IW22",
      "subprocess_id": 220
    },
    {

      "created_on": "Sun, 12 Feb 2023 09:30:05 GMT",
      "error_records": 1,
      "id": 106630,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_05 - Entrega_Viaturas_Sap",
      "subprocess_id": 247
    },
    {

      "created_on": "Sun, 12 Feb 2023 09:06:17 GMT",
      "error_records": 13,
      "id": 106622,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_03 - Despreparacao_Viaturas_IW22",
      "subprocess_id": 220
    },
    {

      "created_on": "Sun, 12 Feb 2023 08:53:59 GMT",
      "error_records": 1,
      "id": 106609,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_05 - Entrega_Viaturas_Sap",
      "subprocess_id": 247
    },
    {

      "created_on": "Sun, 12 Feb 2023 08:32:20 GMT",
      "error_records": 13,
      "id": 106607,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_03 - Despreparacao_Viaturas_IW22",
      "subprocess_id": 220
    },
    {

      "created_on": "Sun, 12 Feb 2023 08:17:58 GMT",
      "error_records": 1,
      "id": 106593,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_05 - Entrega_Viaturas_Sap",
      "subprocess_id": 247
    },
    {

      "created_on": "Sun, 12 Feb 2023 07:42:59 GMT",
      "error_records": 12,
      "id": 106583,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_03 - Despreparacao_Viaturas_IW22",
      "subprocess_id": 220
    },
    {

      "created_on": "Sun, 12 Feb 2023 06:48:57 GMT",
      "error_records": 1,
      "id": 106580,
      "process": "ACT_03 - Despreparação - Entrega Viaturas",
      "process_id": 214,
      "status": "WAITING",
      "subprocess": "ACT_03_02 - Despreparacao_Viaturas_IE02",
      "subprocess_id": 218
    },
    {

      "created_on": "Sun, 12 Feb 2023 06:45:20 GMT",
      "error_records": 2,
      "id": 106567,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 06:45:20 GMT",
      "error_records": 17,
      "id": 106565,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 06:45:20 GMT",
      "error_records": 1,
      "id": 106563,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 06:45:19 GMT",
      "error_records": 15,
      "id": 106562,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 06:19:27 GMT",
      "error_records": 1,
      "id": 106561,
      "process": "ACT_05 - Criação Dados Mestre - Modelle - BringVehicleInFleet",
      "process_id": 224,
      "status": "WAITING",
      "subprocess": "ACT_05_03 - Bring Vehicle InFleet",
      "subprocess_id": 230
    },
    {

      "created_on": "Sun, 12 Feb 2023 05:19:21 GMT",
      "error_records": 1,
      "id": 106554,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 04:13:22 GMT",
      "error_records": 50,
      "id": 106546,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 04:13:22 GMT",
      "error_records": 1,
      "id": 106544,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 03:03:54 GMT",
      "error_records": 57,
      "id": 106538,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 03:03:54 GMT",
      "error_records": 70,
      "id": 106536,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 03:03:54 GMT",
      "error_records": 6,
      "id": 106535,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 03:00:01 GMT",
      "error_records": 1,
      "id": 106532,
      "process": "Q01_Checkout_Renault_Entreposto",
      "process_id": 181,
      "status": "WAITING",
      "subprocess": "Q01_01_Extracao_Matriculas",
      "subprocess_id": 183
    },
    {

      "created_on": "Sun, 12 Feb 2023 03:00:00 GMT",
      "error_records": 1,
      "id": 106531,
      "process": "Q02_Checkout",
      "process_id": 170,
      "status": "WAITING",
      "subprocess": "Q02_01_Extracao_Matriculas",
      "subprocess_id": 172
    },
    {

      "created_on": "Sun, 12 Feb 2023 02:05:23 GMT",
      "error_records": 5,
      "id": 106528,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 02:00:01 GMT",
      "error_records": 1,
      "id": 106520,
      "process": "ACT_02_02 - Importação de Batch Files (SAP)",
      "process_id": 113,
      "status": "WAITING",
      "subprocess": "Importação de Batch Files (SAP)",
      "subprocess_id": 115
    },
    {

      "created_on": "Sun, 12 Feb 2023 00:41:05 GMT",
      "error_records": 63,
      "id": 106507,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sun, 12 Feb 2023 00:30:51 GMT",
      "error_records": 1,
      "id": 106506,
      "process": "ACT_05 - Criação Dados Mestre - Modelle - BringVehicleInFleet",
      "process_id": 224,
      "status": "WAITING",
      "subprocess": "ACT_05_03 - Bring Vehicle InFleet",
      "subprocess_id": 230
    },
    {

      "created_on": "Sat, 11 Feb 2023 23:58:24 GMT",
      "error_records": 62,
      "id": 106501,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sat, 11 Feb 2023 23:58:23 GMT",
      "error_records": 58,
      "id": 106499,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sat, 11 Feb 2023 23:47:52 GMT",
      "error_records": 1,
      "id": 106497,
      "process": "ACT_05 - Criação Dados Mestre - Modelle - BringVehicleInFleet",
      "process_id": 224,
      "status": "WAITING",
      "subprocess": "ACT_05_03 - Bring Vehicle InFleet",
      "subprocess_id": 230
    },
    {

      "created_on": "Sat, 11 Feb 2023 22:47:19 GMT",
      "error_records": 58,
      "id": 106491,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sat, 11 Feb 2023 21:58:30 GMT",
      "error_records": 17,
      "id": 106485,
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "process_id": 312,
      "status": "WAITING",
      "subprocess": "C9 - IPortal",
      "subprocess_id": 301
    },
    {

      "created_on": "Sat, 11 Feb 2023 21:32:21 GMT",
      "error_records": 1,
      "id": 106484,
      "process": "ACT_05 - Criação Dados Mestre - Modelle - BringVehicleInFleet",
      "process_id": 224,
      "status": "WAITING",
      "subprocess": "ACT_05_03 - Bring Vehicle InFleet",
      "subprocess_id": 230
    },
    {

      "created_on": "Sat, 11 Feb 2023 21:31:57 GMT",
      "error_records": 40,
      "id": 106481,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sat, 11 Feb 2023 21:31:56 GMT",
      "error_records": 20,
      "id": 106478,
      "process": "PRC01-Pricing Sixt",
      "process_id": 276,
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing",
      "subprocess_id": 280
    },
    {

      "created_on": "Sat, 11 Feb 2023 21:15:29 GMT",
      "error_records": 5,
      "id": 106476,
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "process_id": 312,
      "status": "WAITING",
      "subprocess": "C9 - IPortal",
      "subprocess_id": 301
    }
]

export const RECORD_DATA: Array<any> = [
    {
      "created_on": "16/02/2023",
      "id": 4329787,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AL-86-CD",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "84"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula AL-86-CD está registada numa Entidade Colectiva com o NIF 515937231"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "EDITING",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329785,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AV-88-ZH",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "15"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Reprocessar. Erro: Invocar Pesquisar Matricula workflow"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329784,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AU-67-PQ",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "14"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula AU-67-PQ está registada numa Entidade Colectiva com o NIF 501335854"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329783,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "21-ZP-93",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "83"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula 21-ZP-93 está registada numa Entidade Colectiva com o NIF 508722977"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329778,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AG-98-QO",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "14"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula AG-98-QO está registada numa Entidade Colectiva com o NIF 501335854"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329777,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AI-11-IF",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "14"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula AI-11-IF está registada numa Entidade Colectiva com o NIF 501335854"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329776,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "60-ZM-08",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "13"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula 60-ZM-08 está registada numa Entidade Colectiva com o NIF 501999230"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329773,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "56-XQ-97",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "11"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Reprocessar. Erro: Invocar Pesquisar Matricula workflow"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329762,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "25-JV-19",
          "Motivo": "OUTRA MARCA",
          "Nome": "BC",
          "Origem": "Google Sheet",
          "PV": "11"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: OUT - Pesquisar Matricula: Reprocessar. Não acedeu correctamente: Selector Resultados da Pesquisa "
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329756,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AX-01-IP",
          "Motivo": "PERSONALIDADE CLIENTE",
          "Nome": "",
          "Origem": "Google Sheet",
          "PV": "13"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula AX-01-IP está registada numa Entidade Colectiva com o NIF 517233908"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329747,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "77-TD-65",
          "Motivo": "RETORNO",
          "Nome": "FS",
          "Origem": "Google Sheet",
          "PV": "11"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107558,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula 77-TD-65 está registada numa Entidade Colectiva com o NIF 500172382"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329715,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AL-86-CD",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "84"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107557,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula AL-86-CD está registada numa Entidade Colectiva com o NIF 515937231"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329713,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AV-88-ZH",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "15"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107557,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Reprocessar. Erro: Invocar Pesquisar Matricula workflow"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329712,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AU-67-PQ",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "14"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107557,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula AU-67-PQ está registada numa Entidade Colectiva com o NIF 501335854"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329711,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "21-ZP-93",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "83"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107557,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: OUT - Pesquisar Matricula: Reprocessar. Não acedeu correctamente: Selector Resultados da Pesquisa "
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329706,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AG-98-QO",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "14"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107557,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula AG-98-QO está registada numa Entidade Colectiva com o NIF 501335854"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329705,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AI-11-IF",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "14"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107557,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: OUT - Client Information NavigationReprocessar. Não acedeu correctamente: Selecionar na Tab Cliente"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329704,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "60-ZM-08",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "13"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107557,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula 60-ZM-08 está registada numa Entidade Colectiva com o NIF 501999230"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329701,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "56-XQ-97",
          "Motivo": "",
          "Nome": "",
          "Origem": "Colisão",
          "PV": "11"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107557,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Reprocessar. Erro: Invocar Pesquisar Matricula workflow"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329684,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "AX-01-IP",
          "Motivo": "PERSONALIDADE CLIENTE",
          "Nome": "",
          "Origem": "Google Sheet",
          "PV": "13"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107557,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula AX-01-IP está registada numa Entidade Colectiva com o NIF 517233908"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329675,
      "inputs": {
        "data": {
          "Data": "02/15/2023",
          "Matrícula": "77-TD-65",
          "Motivo": "RETORNO",
          "Nome": "FS",
          "Origem": "Google Sheet",
          "PV": "11"
        },
        "fields": {
          "Data": "String",
          "Matrícula": "String",
          "Motivo": "String",
          "Nome": "String",
          "Origem": "String",
          "PV": "String"
        }
      },
      "job_id": 107557,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Matricula 77-TD-65 está registada numa Entidade Colectiva com o NIF 500172382"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "Q02_Checkout",
      "status": "ERROR",
      "subprocess": "Q02_02_Alteracao_Emails_Telefones"
    },
    {
      "created_on": "16/02/2023",
      "id": 4329034,
      "inputs": {
        "data": {
          "Mensagem RPA": ""
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "job_id": 107547,
      "outputs": {
        "data": {
          "Mensagem": "Sucesso a enviar a Flag para o subprocess Submissão de Reclamações"
        },
        "fields": {
          "Mensagem": "String"
        }
      },
      "process": "Q01_Checkout_Renault_Entreposto",
      "status": "ERROR",
      "subprocess": "Q01_01_Extracao_Matriculas"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323814,
      "inputs": {
        "data": {
          "Comentário": "Pf conferir o valor da PO, obg",
          "Fatura JAP RAC": "JAPRAC_73198813.xml",
          "IPortal": "Encaminhar",
          "Invoice Date": "30-01-2023",
          "Invoice Number": "11220028",
          "Invoice Type": "Fatura",
          "NIF": "PT500035679",
          "Order Number": "4500076808",
          "Total": "552.80",
          "Total Without Rate": "449.43",
          "User": "Inês Machado"
        },
        "fields": {
          "Comentário": "String",
          "Fatura JAP RAC": "String",
          "IPortal": "String",
          "Invoice Date": "String",
          "Invoice Number": "String",
          "Invoice Type": "String",
          "NIF": "String",
          "Order Number": "String",
          "Total": "String",
          "Total Without Rate": "String",
          "User": "String"
        }
      },
      "job_id": 107448,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Procurar Faturas em IPortal"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "status": "ERROR",
      "subprocess": "C9 - IPortal"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323809,
      "inputs": {
        "data": {
          "Comentário": "Pf conferir o valor da PO, obg",
          "Fatura JAP RAC": "JAPRAC_73198496.xml",
          "IPortal": "Encaminhar",
          "Invoice Date": "30-01-2023",
          "Invoice Number": "11220029",
          "Invoice Type": "Fatura",
          "NIF": "PT500035679",
          "Order Number": "4500075516",
          "Total": "3762.46",
          "Total Without Rate": "3058.91",
          "User": "Inês Machado"
        },
        "fields": {
          "Comentário": "String",
          "Fatura JAP RAC": "String",
          "IPortal": "String",
          "Invoice Date": "String",
          "Invoice Number": "String",
          "Invoice Type": "String",
          "NIF": "String",
          "Order Number": "String",
          "Total": "String",
          "Total Without Rate": "String",
          "User": "String"
        }
      },
      "job_id": 107448,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Procurar Faturas em IPortal"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "status": "ERROR",
      "subprocess": "C9 - IPortal"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323795,
      "inputs": {
        "data": {
          "Comentário": "Pf conferir o valor da PO, obg",
          "Fatura JAP RAC": "JAPRAC_73191355.xml",
          "IPortal": "Encaminhar",
          "Invoice Date": "31-01-2023",
          "Invoice Number": "FTEFE20232200/001526",
          "Invoice Type": "Fatura",
          "NIF": "PT503123226",
          "Order Number": "4500074802",
          "Total": "752.18",
          "Total Without Rate": "611.60",
          "User": "Daniela Vasconcelos"
        },
        "fields": {
          "Comentário": "String",
          "Fatura JAP RAC": "String",
          "IPortal": "String",
          "Invoice Date": "String",
          "Invoice Number": "String",
          "Invoice Type": "String",
          "NIF": "String",
          "Order Number": "String",
          "Total": "String",
          "Total Without Rate": "String",
          "User": "String"
        }
      },
      "job_id": 107448,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Encaminhar em IPortal"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "status": "ERROR",
      "subprocess": "C9 - IPortal"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323779,
      "inputs": {
        "data": {
          "Fatura JAP RAC": "JAPRAC_73223320.xml",
          "Invoice Date": "24-01-2023",
          "Invoice Number": "FAOF3FC/000350",
          "Invoice Type": "Fatura",
          "NIF": "500190992",
          "Order Number": "ainda sem ne associada",
          "Total": "334.13",
          "Total Without Rate": "271.65",
          "codigoNIF": "PT"
        },
        "fields": {
          "Fatura JAP RAC": "String",
          "Invoice Date": "String",
          "Invoice Number": "String",
          "Invoice Type": "String",
          "NIF": "String",
          "Order Number": "String",
          "Total": "String",
          "Total Without Rate": "String",
          "codigoNIF": "String"
        }
      },
      "job_id": 107447,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Pedido ainda sem ne associada não existe em SAP "
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "status": "ERROR",
      "subprocess": "C9 - Lançamento de Faturas SAP"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323778,
      "inputs": {
        "data": {
          "Fatura JAP RAC": "JAPRAC_73223303.xml",
          "Invoice Date": "30-01-2023",
          "Invoice Number": "22201281",
          "Invoice Type": "Fatura",
          "NIF": "501262300",
          "Order Number": "ainda sem ne associada",
          "Total": "45.47",
          "Total Without Rate": "36.97",
          "codigoNIF": "PT"
        },
        "fields": {
          "Fatura JAP RAC": "String",
          "Invoice Date": "String",
          "Invoice Number": "String",
          "Invoice Type": "String",
          "NIF": "String",
          "Order Number": "String",
          "Total": "String",
          "Total Without Rate": "String",
          "codigoNIF": "String"
        }
      },
      "job_id": 107447,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Pedido ainda sem ne associada não existe em SAP "
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "status": "ERROR",
      "subprocess": "C9 - Lançamento de Faturas SAP"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323777,
      "inputs": {
        "data": {
          "Fatura JAP RAC": "JAPRAC_73213607.xml",
          "Invoice Date": "12-01-2023",
          "Invoice Number": "FAOF3FC/000101",
          "Invoice Type": "Fatura",
          "NIF": "503019569",
          "Order Number": "NOTA DE ENCOMENDA AINDA NÃO ASSOCIADA.",
          "Total": "285.84",
          "Total Without Rate": "232.39",
          "codigoNIF": "PT"
        },
        "fields": {
          "Fatura JAP RAC": "String",
          "Invoice Date": "String",
          "Invoice Number": "String",
          "Invoice Type": "String",
          "NIF": "String",
          "Order Number": "String",
          "Total": "String",
          "Total Without Rate": "String",
          "codigoNIF": "String"
        }
      },
      "job_id": 107447,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Pedido NOTA DE ENCOMENDA AINDA NÃO ASSOCIADA. não existe em SAP "
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "status": "ERROR",
      "subprocess": "C9 - Lançamento de Faturas SAP"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323776,
      "inputs": {
        "data": {
          "Fatura JAP RAC": "JAPRAC_73209218.xml",
          "Invoice Date": "06-02-2023",
          "Invoice Number": "FTSG23/189",
          "Invoice Type": "Fatura",
          "NIF": "512032904",
          "Order Number": "NOTA DE ENCOMENDA AINDA NÃO ASSOCIADA.",
          "Total": "430.75",
          "Total Without Rate": "371.34",
          "codigoNIF": "PT"
        },
        "fields": {
          "Fatura JAP RAC": "String",
          "Invoice Date": "String",
          "Invoice Number": "String",
          "Invoice Type": "String",
          "NIF": "String",
          "Order Number": "String",
          "Total": "String",
          "Total Without Rate": "String",
          "codigoNIF": "String"
        }
      },
      "job_id": 107447,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Pedido NOTA DE ENCOMENDA AINDA NÃO ASSOCIADA. não existe em SAP "
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "status": "ERROR",
      "subprocess": "C9 - Lançamento de Faturas SAP"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323774,
      "inputs": {
        "data": {
          "Fatura JAP RAC": "JAPRAC_73208902.xml",
          "Invoice Date": "27-01-2023",
          "Invoice Number": "FTFT1/149",
          "Invoice Type": "Fatura",
          "NIF": "507251040",
          "Order Number": "NOTA DE ENCOMENDA AINDA NÃO ASSOCIADA.",
          "Total": "1959.61",
          "Total Without Rate": "1593.18",
          "codigoNIF": "PT"
        },
        "fields": {
          "Fatura JAP RAC": "String",
          "Invoice Date": "String",
          "Invoice Number": "String",
          "Invoice Type": "String",
          "NIF": "String",
          "Order Number": "String",
          "Total": "String",
          "Total Without Rate": "String",
          "codigoNIF": "String"
        }
      },
      "job_id": 107447,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Pedido NOTA DE ENCOMENDA AINDA NÃO ASSOCIADA. não existe em SAP "
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "status": "ERROR",
      "subprocess": "C9 - Lançamento de Faturas SAP"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323773,
      "inputs": {
        "data": {
          "Fatura JAP RAC": "JAPRAC_73208545.xml",
          "Invoice Date": "26-01-2023",
          "Invoice Number": "FAC4/84",
          "Invoice Type": "Fatura",
          "NIF": "503688894",
          "Order Number": "NOTA DE ENCOMENDA AINDA NÃO ASSOCIADA.",
          "Total": "1003.02",
          "Total Without Rate": "815.46",
          "codigoNIF": "PT"
        },
        "fields": {
          "Fatura JAP RAC": "String",
          "Invoice Date": "String",
          "Invoice Number": "String",
          "Invoice Type": "String",
          "NIF": "String",
          "Order Number": "String",
          "Total": "String",
          "Total Without Rate": "String",
          "codigoNIF": "String"
        }
      },
      "job_id": 107447,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Pedido NOTA DE ENCOMENDA AINDA NÃO ASSOCIADA. não existe em SAP "
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "status": "ERROR",
      "subprocess": "C9 - Lançamento de Faturas SAP"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323769,
      "inputs": {
        "data": {
          "Fatura JAP RAC": "JAPRAC_73208024.xml",
          "Invoice Date": "06-02-2023",
          "Invoice Number": "WFJ/142/2023",
          "Invoice Type": "Fatura",
          "NIF": "500003165",
          "Order Number": "NÃO PERTENCE AS OFICINAS",
          "Total": "24.21",
          "Total Without Rate": "19.68",
          "codigoNIF": "PT"
        },
        "fields": {
          "Fatura JAP RAC": "String",
          "Invoice Date": "String",
          "Invoice Number": "String",
          "Invoice Type": "String",
          "NIF": "String",
          "Order Number": "String",
          "Total": "String",
          "Total Without Rate": "String",
          "codigoNIF": "String"
        }
      },
      "job_id": 107447,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Pedido NÃO PERTENCE AS OFICINAS não existe em SAP "
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9 - Lançamento de Faturas JAPRAC",
      "status": "ERROR",
      "subprocess": "C9 - Lançamento de Faturas SAP"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323686,
      "inputs": {
        "data": {
          "Acção": "Falso",
          "Data da Fatura": "02/14/2023 00:00:00",
          "Data de Entrada": "02/15/2023 00:00:00",
          "IVA": "56.47",
          "Item": "10",
          "Nº Fatura": "2925010195",
          "Referência": "1630530",
          "Tipo de Fatura": "Fatura Ext Garantia",
          "Valor Total": "301.97",
          "Valor líquido": "245.5"
        },
        "fields": {
          "Acção": "String",
          "Data da Fatura": "String",
          "Data de Entrada": "String",
          "IVA": "String",
          "Item": "String",
          "Nº Fatura": "String",
          "Referência": "String",
          "Tipo de Fatura": "String",
          "Valor Total": "String",
          "Valor líquido": "String"
        }
      },
      "job_id": 107443,
      "outputs": {
        "data": {
          "Mensagem RPA": "Reprocessar. Nome ficheiro PDF inválido"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C7 - Faturação Nissan",
      "status": "ERROR",
      "subprocess": "FAT_NISSAN 1 - Outras Faturas"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323685,
      "inputs": {
        "data": {
          "Acção": "Falso",
          "Data da Fatura": "02/14/2023 00:00:00",
          "Data de Entrada": "02/14/2023 00:00:00",
          "IVA": "606.18",
          "Item": "20",
          "Nº Fatura": "2965042863",
          "Referência": "NEW VEHICLE RETAIL INCENTIVE",
          "Tipo de Fatura": "N. Crédito Incentivo",
          "Valor Total": "3241.76",
          "Valor líquido": "2635.58"
        },
        "fields": {
          "Acção": "String",
          "Data da Fatura": "String",
          "Data de Entrada": "String",
          "IVA": "String",
          "Item": "String",
          "Nº Fatura": "String",
          "Referência": "String",
          "Tipo de Fatura": "String",
          "Valor Total": "String",
          "Valor líquido": "String"
        }
      },
      "job_id": 107443,
      "outputs": {
        "data": {
          "Mensagem RPA": "Reprocessar. Nome ficheiro PDF inválido"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C7 - Faturação Nissan",
      "status": "ERROR",
      "subprocess": "FAT_NISSAN 1 - Outras Faturas"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323683,
      "inputs": {
        "data": {
          "Acção": "Falso",
          "Data da Fatura": "02/14/2023 00:00:00",
          "Data de Entrada": "02/14/2023 00:00:00",
          "IVA": "679.36",
          "Item": "10",
          "Nº Fatura": "2965042864",
          "Referência": "NEW VEHICLE RETAIL INCENTIVE",
          "Tipo de Fatura": "N. Crédito Incentivo",
          "Valor Total": "3633.11",
          "Valor líquido": "2953.75"
        },
        "fields": {
          "Acção": "String",
          "Data da Fatura": "String",
          "Data de Entrada": "String",
          "IVA": "String",
          "Item": "String",
          "Nº Fatura": "String",
          "Referência": "String",
          "Tipo de Fatura": "String",
          "Valor Total": "String",
          "Valor líquido": "String"
        }
      },
      "job_id": 107443,
      "outputs": {
        "data": {
          "Mensagem RPA": "Reprocessar. Nome ficheiro PDF inválido"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C7 - Faturação Nissan",
      "status": "ERROR",
      "subprocess": "FAT_NISSAN 1 - Outras Faturas"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323682,
      "inputs": {
        "data": {
          "Acção": "Falso",
          "Data da Fatura": "02/14/2023 00:00:00",
          "Data de Entrada": "02/14/2023 00:00:00",
          "IVA": "839.51",
          "Item": "10",
          "Nº Fatura": "2965042865",
          "Referência": "NEW VEHICLE RETAIL INCENTIVE",
          "Tipo de Fatura": "N. Crédito Incentivo",
          "Valor Total": "4489.56",
          "Valor líquido": "3650.05"
        },
        "fields": {
          "Acção": "String",
          "Data da Fatura": "String",
          "Data de Entrada": "String",
          "IVA": "String",
          "Item": "String",
          "Nº Fatura": "String",
          "Referência": "String",
          "Tipo de Fatura": "String",
          "Valor Total": "String",
          "Valor líquido": "String"
        }
      },
      "job_id": 107443,
      "outputs": {
        "data": {
          "Mensagem RPA": "Reprocessar. Nome ficheiro PDF inválido"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C7 - Faturação Nissan",
      "status": "ERROR",
      "subprocess": "FAT_NISSAN 1 - Outras Faturas"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323681,
      "inputs": {
        "data": {
          "Acção": "Falso",
          "Data da Fatura": "02/14/2023 00:00:00",
          "Data de Entrada": "02/14/2023 00:00:00",
          "IVA": "943.75",
          "Item": "20",
          "Nº Fatura": "2965042866",
          "Referência": "NEW VEHICLE RETAIL INCENTIVE",
          "Tipo de Fatura": "N. Crédito Incentivo",
          "Valor Total": "5047.01",
          "Valor líquido": "4103.26"
        },
        "fields": {
          "Acção": "String",
          "Data da Fatura": "String",
          "Data de Entrada": "String",
          "IVA": "String",
          "Item": "String",
          "Nº Fatura": "String",
          "Referência": "String",
          "Tipo de Fatura": "String",
          "Valor Total": "String",
          "Valor líquido": "String"
        }
      },
      "job_id": 107443,
      "outputs": {
        "data": {
          "Mensagem RPA": "Reprocessar. Nome ficheiro PDF inválido"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C7 - Faturação Nissan",
      "status": "ERROR",
      "subprocess": "FAT_NISSAN 1 - Outras Faturas"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323680,
      "inputs": {
        "data": {
          "Acção": "Falso",
          "Data da Fatura": "02/15/2023 00:00:00",
          "Data de Entrada": "02/15/2023 00:00:00",
          "IVA": "678.5",
          "Item": "50",
          "Nº Fatura": "2965042872",
          "Referência": "NEW VEHICLE RETAIL INCENTIVE",
          "Tipo de Fatura": "N. Crédito Incentivo",
          "Valor Total": "3628.5",
          "Valor líquido": "2950"
        },
        "fields": {
          "Acção": "String",
          "Data da Fatura": "String",
          "Data de Entrada": "String",
          "IVA": "String",
          "Item": "String",
          "Nº Fatura": "String",
          "Referência": "String",
          "Tipo de Fatura": "String",
          "Valor Total": "String",
          "Valor líquido": "String"
        }
      },
      "job_id": 107443,
      "outputs": {
        "data": {
          "Mensagem RPA": "Reprocessar. Nome ficheiro PDF inválido"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C7 - Faturação Nissan",
      "status": "ERROR",
      "subprocess": "FAT_NISSAN 1 - Outras Faturas"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323679,
      "inputs": {
        "data": {
          "Acção": "Falso",
          "Data da Fatura": "02/15/2023 00:00:00",
          "Data de Entrada": "02/15/2023 00:00:00",
          "IVA": "262.92",
          "Item": "30",
          "Nº Fatura": "2965042873",
          "Referência": "NEW VEHICLE RETAIL INCENTIVE",
          "Tipo de Fatura": "N. Crédito Incentivo",
          "Valor Total": "1406.04",
          "Valor líquido": "1143.12"
        },
        "fields": {
          "Acção": "String",
          "Data da Fatura": "String",
          "Data de Entrada": "String",
          "IVA": "String",
          "Item": "String",
          "Nº Fatura": "String",
          "Referência": "String",
          "Tipo de Fatura": "String",
          "Valor Total": "String",
          "Valor líquido": "String"
        }
      },
      "job_id": 107443,
      "outputs": {
        "data": {
          "Mensagem RPA": "Reprocessar. Nome ficheiro PDF inválido"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C7 - Faturação Nissan",
      "status": "ERROR",
      "subprocess": "FAT_NISSAN 1 - Outras Faturas"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323210,
      "inputs": {
        "data": {
          "Mensagem RPA": ""
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "job_id": 107432,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Sem ficheiros a processar"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9_0 - Validacao PO",
      "status": "ERROR",
      "subprocess": "C9_0_Validacao PO"
    },
    {
      "created_on": "15/02/2023",
      "id": 4323207,
      "inputs": {
        "data": {
          "Mensagem RPA": ""
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "job_id": 107428,
      "outputs": {
        "data": {
          "Mensagem RPA": "Business Error: Sem ficheiros a processar"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "C9_0 - Validacao PO",
      "status": "ERROR",
      "subprocess": "C9_0_Validacao PO"
    },
    {
      "created_on": "15/02/2023",
      "id": 4320545,
      "inputs": {
        "data": {
          "Mensagem RPA": ""
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "job_id": 107373,
      "outputs": {
        "data": {
          "Mensagem RPA": "Foram importados 420 registos"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_01-CarregarTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4315721,
      "inputs": {
        "data": {
          "Date End": "13-04-2023",
          "Date Start": "13-04-2023",
          "F Type": "P",
          "Id da linha": "37963183",
          "Lor End": "10",
          "Lor Start": "10",
          "Priority": "4",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "PWAR",
          "Y Type": "Uda",
          "Yield": "P35"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107244,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312594,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38530869",
          "Lor End": "11",
          "Lor Start": "11",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDAE",
          "Y Type": "Uda",
          "Yield": "P54"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312593,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38531001",
          "Lor End": "1",
          "Lor Start": "0",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CFAR",
          "Y Type": "Uda",
          "Yield": "P59"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312592,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38530873",
          "Lor End": "6",
          "Lor Start": "6",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IVMR",
          "Y Type": "Uda",
          "Yield": "P34"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312591,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38530913",
          "Lor End": "13",
          "Lor Start": "13",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IFMR",
          "Y Type": "Uda",
          "Yield": "P50"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312590,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38532317",
          "Lor End": "2",
          "Lor Start": "2",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "FDAR",
          "Y Type": "Uda",
          "Yield": "P50"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312589,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38531649",
          "Lor End": "1",
          "Lor Start": "0",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDMM",
          "Y Type": "Uda",
          "Yield": "P66"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312588,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38531313",
          "Lor End": "10",
          "Lor Start": "10",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CDAR",
          "Y Type": "Uda",
          "Yield": "P54"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312587,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38531589",
          "Lor End": "4",
          "Lor Start": "4",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IDAR",
          "Y Type": "Uda",
          "Yield": "P54"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312586,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38531653",
          "Lor End": "10",
          "Lor Start": "10",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CFAR",
          "Y Type": "Uda",
          "Yield": "P54"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312585,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38531953",
          "Lor End": "1",
          "Lor Start": "0",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CDAR",
          "Y Type": "Uda",
          "Yield": "P59"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312584,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39816046",
          "Lor End": "999",
          "Lor Start": "25",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CDAR",
          "Y Type": "Uda",
          "Yield": "P46"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312583,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39085508",
          "Lor End": "12",
          "Lor Start": "12",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDAE",
          "Y Type": "Uda",
          "Yield": "P53"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312582,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39815618",
          "Lor End": "11",
          "Lor Start": "11",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CDAR",
          "Y Type": "Uda",
          "Yield": "P52"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312581,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38532717",
          "Lor End": "12",
          "Lor Start": "12",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CFAR",
          "Y Type": "Uda",
          "Yield": "P50"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312580,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "38532533",
          "Lor End": "1",
          "Lor Start": "0",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "MDMR",
          "Y Type": "Uda",
          "Yield": "P66"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "ERROR",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312579,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39085132",
          "Lor End": "1",
          "Lor Start": "0",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "FDAR",
          "Y Type": "Uda",
          "Yield": "P51"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312578,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39814546",
          "Lor End": "24",
          "Lor Start": "16",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CFAR",
          "Y Type": "Uda",
          "Yield": "P46"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312577,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39084344",
          "Lor End": "4",
          "Lor Start": "4",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDAE",
          "Y Type": "Uda",
          "Yield": "P58"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312576,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39814058",
          "Lor End": "24",
          "Lor Start": "16",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CDAR",
          "Y Type": "Uda",
          "Yield": "P46"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312575,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39084564",
          "Lor End": "2",
          "Lor Start": "2",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IVMR",
          "Y Type": "Uda",
          "Yield": "P38"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312574,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39813506",
          "Lor End": "13",
          "Lor Start": "13",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IVMR",
          "Y Type": "Uda",
          "Yield": "P39"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312573,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39086756",
          "Lor End": "4",
          "Lor Start": "4",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CWMR",
          "Y Type": "Uda",
          "Yield": "P51"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312572,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39085756",
          "Lor End": "12",
          "Lor Start": "12",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IDMR",
          "Y Type": "Uda",
          "Yield": "P43"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312571,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39085524",
          "Lor End": "15",
          "Lor Start": "15",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDAE",
          "Y Type": "Uda",
          "Yield": "P53"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312570,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39089276",
          "Lor End": "6",
          "Lor Start": "6",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IDMR",
          "Y Type": "Uda",
          "Yield": "P53"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312569,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39086736",
          "Lor End": "8",
          "Lor Start": "8",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IDAR",
          "Y Type": "Uda",
          "Yield": "P51"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312568,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39086348",
          "Lor End": "10",
          "Lor Start": "10",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IDAR",
          "Y Type": "Uda",
          "Yield": "P51"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312567,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39815242",
          "Lor End": "13",
          "Lor Start": "13",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CFAR",
          "Y Type": "Uda",
          "Yield": "P49"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312566,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39812834",
          "Lor End": "14",
          "Lor Start": "14",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CDAR",
          "Y Type": "Uda",
          "Yield": "P48"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312565,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39812714",
          "Lor End": "13",
          "Lor Start": "13",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CDAR",
          "Y Type": "Uda",
          "Yield": "P49"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312564,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39088012",
          "Lor End": "13",
          "Lor Start": "13",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDAE",
          "Y Type": "Uda",
          "Yield": "P53"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312563,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39812426",
          "Lor End": "4",
          "Lor Start": "4",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IDMR",
          "Y Type": "Uda",
          "Yield": "P52"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Não conseguiu navegar em cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312561,
      "inputs": {
        "data": {
          "Date End": "01-03-2023",
          "Date Start": "01-03-2023",
          "F Type": "P",
          "Id da linha": "39815222",
          "Lor End": "14",
          "Lor Start": "14",
          "Priority": "6",
          "Station Out Arm": "42506",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CFAR",
          "Y Type": "Uda",
          "Yield": "P48"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107205,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Não conseguiu pesquisar linhas no formulário de Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312499,
      "inputs": {
        "data": {
          "Date End": "21-02-2023",
          "Date Start": "21-02-2023",
          "F Type": "P",
          "Id da linha": "37940271",
          "Lor End": "1",
          "Lor Start": "0",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDMM",
          "Y Type": "Uda",
          "Yield": "P67"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107204,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Não conseguiu navegar em cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4312255,
      "inputs": {
        "data": {
          "Date End": "04-03-2023",
          "Date Start": "04-03-2023",
          "F Type": "P",
          "Id da linha": "39083800",
          "Lor End": "12",
          "Lor Start": "12",
          "Priority": "5",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "IFMR",
          "Y Type": "Uda",
          "Yield": "P43"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107201,
      "outputs": {
        "data": {
          "Mensagem RPA": "Error: Não conseguiu navegar em cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310833,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936220",
          "Lor End": "7",
          "Lor Start": "7",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CDAR",
          "Y Type": "Uda",
          "Yield": "P37"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310832,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936216",
          "Lor End": "12",
          "Lor Start": "12",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CWMR",
          "Y Type": "Uda",
          "Yield": "P36"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310831,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936088",
          "Lor End": "8",
          "Lor Start": "8",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "FFAR",
          "Y Type": "Uda",
          "Yield": "P37"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310830,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936052",
          "Lor End": "3",
          "Lor Start": "3",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "FFMR",
          "Y Type": "Uda",
          "Yield": "P40"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310829,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38939224",
          "Lor End": "2",
          "Lor Start": "2",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CFMR",
          "Y Type": "Uda",
          "Yield": "P42"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310828,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936124",
          "Lor End": "10",
          "Lor Start": "10",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CCCC",
          "Y Type": "Uda",
          "Yield": "P37"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310827,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38939204",
          "Lor End": "14",
          "Lor Start": "14",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "MDMR",
          "Y Type": "Uda",
          "Yield": "P36"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310826,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38939200",
          "Lor End": "3",
          "Lor Start": "3",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDAR",
          "Y Type": "Uda",
          "Yield": "P37"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310825,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936248",
          "Lor End": "10",
          "Lor Start": "10",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CWMR",
          "Y Type": "Uda",
          "Yield": "P36"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310824,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936128",
          "Lor End": "9",
          "Lor Start": "9",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CCCC",
          "Y Type": "Uda",
          "Yield": "P37"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310823,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38939152",
          "Lor End": "8",
          "Lor Start": "8",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "MDMR",
          "Y Type": "Uda",
          "Yield": "P37"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310822,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38939148",
          "Lor End": "6",
          "Lor Start": "6",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDAR",
          "Y Type": "Uda",
          "Yield": "P35"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310821,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936040",
          "Lor End": "14",
          "Lor Start": "14",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "FFAR",
          "Y Type": "Uda",
          "Yield": "P35"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310820,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936032",
          "Lor End": "11",
          "Lor Start": "11",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "FDAR",
          "Y Type": "Uda",
          "Yield": "P39"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310819,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38939032",
          "Lor End": "5",
          "Lor Start": "5",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "CFAR",
          "Y Type": "Uda",
          "Yield": "P37"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310818,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936008",
          "Lor End": "14",
          "Lor Start": "14",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "FDAR",
          "Y Type": "Uda",
          "Yield": "P38"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310817,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936044",
          "Lor End": "13",
          "Lor Start": "13",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "FFAR",
          "Y Type": "Uda",
          "Yield": "P35"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310816,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38936028",
          "Lor End": "13",
          "Lor Start": "13",
          "Priority": "6",
          "Station Out Arm": "41020",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "FDAR",
          "Y Type": "Uda",
          "Yield": "P38"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310815,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38938828",
          "Lor End": "1",
          "Lor Start": "0",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDAE",
          "Y Type": "Uda",
          "Yield": "P40"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310814,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38938824",
          "Lor End": "15",
          "Lor Start": "15",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "FDAR",
          "Y Type": "Uda",
          "Yield": "P37"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310813,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38938780",
          "Lor End": "15",
          "Lor Start": "15",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDAR",
          "Y Type": "Uda",
          "Yield": "P33"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    },
    {
      "created_on": "14/02/2023",
      "id": 4310812,
      "inputs": {
        "data": {
          "Date End": "26-06-2023",
          "Date Start": "26-06-2023",
          "F Type": "P",
          "Id da linha": "38938760",
          "Lor End": "24",
          "Lor Start": "16",
          "Priority": "6",
          "Station Out Arm": "43044",
          "Time End": "23:59:00",
          "Time Start": "00:00:00",
          "Vehicle Group Arm": "EDAE",
          "Y Type": "Uda",
          "Yield": "P31"
        },
        "fields": {
          "Date End": "String",
          "Date Start": "String",
          "F Type": "String",
          "Id da linha": "String",
          "Lor End": "String",
          "Lor Start": "String",
          "Priority": "String",
          "Station Out Arm": "String",
          "Time End": "String",
          "Time Start": "String",
          "Vehicle Group Arm": "String",
          "Y Type": "String",
          "Yield": "String"
        }
      },
      "job_id": 107167,
      "outputs": {
        "data": {
          "Mensagem RPA": "Não conseguiu realizar login em Cobra"
        },
        "fields": {
          "Mensagem RPA": "String"
        }
      },
      "process": "PRC01-Pricing Sixt",
      "status": "WAITING",
      "subprocess": "PRC01_02-ProcessaTarefasPricing"
    }
]

export const OUTPUT_DATA_BY_INPUT_RECORD: any = {
  "count": 1,
  "results": [
      {
          "id": 92589,
          "createdOn": "2021-10-04T16:44:45",
          "status": "UNPROCESSED",
          "lastStatusUpdate": "2021-10-04T16:44:45",
          "job": {
              "id": 7525,
              "priority": 10,
              "currentStatus": "ERROR"
          },
          "process": {
              "id": 153,
              "name": "RH_01 - Admiss\u00f5es Seguran\u00e7a Social Direta",
              "isActive": true
          },
          "subprocess": {
              "id": 155,
              "name": "Admiss\u00f5es Seguran\u00e7a Social Direta",
              "isActive": true
          },
          "data": {
              "2": {
                  "value": "",
                  "name": "NOME COLABORADOR",
                  "code": "NOME COLABORADOR",
                  "type": "String",
                  "isHidden": false,
                  "isActive": true
              },
              "3": {
                  "value": "Error: Credencial n\u00e3o configurada",
                  "name": "Mensagem RPA",
                  "code": "Mensagem RPA",
                  "type": "String",
                  "isHidden": false,
                  "isActive": true
              },
              "4": {
                  "value": null,
                  "name": "File",
                  "code": "File",
                  "type": "File",
                  "isHidden": false,
                  "isActive": true
              }
          }
      },
      {
        "id": 92590,
        "createdOn": "2021-10-04T16:44:45",
        "status": "UNPROCESSED",
        "lastStatusUpdate": "2021-10-04T16:44:45",
        "job": {
            "id": 7525,
            "priority": 10,
            "currentStatus": "ERROR"
        },
        "process": {
            "id": 153,
            "name": "RH_01 - Admiss\u00f5es Seguran\u00e7a Social Direta",
            "isActive": true
        },
        "subprocess": {
            "id": 155,
            "name": "Admiss\u00f5es Seguran\u00e7a Social Direta",
            "isActive": true
        },
        "data": {
            "2": {
                "value": "",
                "name": "NOME COLABORADOR",
                "code": "NOME COLABORADOR",
                "type": "String",
                "isHidden": false,
                "isActive": true
            },
            "3": {
                "value": "Error: Credencial n\u00e3o configurada",
                "name": "Mensagem RPA",
                "code": "Mensagem RPA",
                "type": "String",
                "isHidden": false,
                "isActive": true
            },
            "4": {
                "value": null,
                "name": "File",
                "code": "File",
                "type": "File",
                "isHidden": false,
                "isActive": true
            }
        }
    },
    {
      "id": 92591,
      "createdOn": "2021-10-04T16:44:45",
      "status": "UNPROCESSED",
      "lastStatusUpdate": "2021-10-04T16:44:45",
      "job": {
          "id": 7525,
          "priority": 10,
          "currentStatus": "ERROR"
      },
      "process": {
          "id": 153,
          "name": "RH_01 - Admiss\u00f5es Seguran\u00e7a Social Direta",
          "isActive": true
      },
      "subprocess": {
          "id": 155,
          "name": "Admiss\u00f5es Seguran\u00e7a Social Direta",
          "isActive": true
      },
      "data": {
          "2": {
              "value": "",
              "name": "NOME COLABORADOR",
              "code": "NOME COLABORADOR",
              "type": "String",
              "isHidden": false,
              "isActive": true
          },
          "3": {
              "value": "Error: Credencial n\u00e3o configurada",
              "name": "Mensagem RPA",
              "code": "Mensagem RPA",
              "type": "String",
              "isHidden": false,
              "isActive": true
          },
          "4": {
              "value": null,
              "name": "File",
              "code": "File",
              "type": "File",
              "isHidden": false,
              "isActive": true
          }
      }
  }
  ]
}