import React, { FC, useEffect, useState } from 'react';
import './TodoTabFilter.scss';
import { Field, Input, Button } from '@fluentui/react-components';
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { ProcessService } from '../../services/ProcessService';
import MultiTagCombobox from '../MultiTagCombobox/MultiTagCombobox';
import { ApiResult } from '../../utils/helpers';
import { FieldsService } from '../../services/FieldsService';
import { IField } from '../../interfaces/IFIeld';
import { INamedEntity } from '../../interfaces/INamedEntity';
import { NamedEntityCheckType } from '../../types/NamedEntityCheckType';

type FieldType = IField & {
	checked: boolean
}
interface IJobFiter {
	processIds?: number[]
	subprocessIds?: number[]
	startDate?: string
	endDate?: string
	jobIds?: number[]
}
interface TodoTabFilterProps {
	onApplyFilters: any
}

const TodoTabFilter: FC<TodoTabFilterProps> = (props) => {

	useEffect(() => {
		ProcessService.getProcessesNames().then((result: ApiResult<Array<INamedEntity>>) => {
			setProcesses(result.results.map((r: any) => ({...r, checked: false})))
		})
		getSubProcessesName([])
		getFieldsNames([])
	}, [])

	const [subprocesses, setSubProcesses] = useState<NamedEntityCheckType[]>([])
	const [processes, setProcesses] = useState<NamedEntityCheckType[]>([])
	const [fields, setFields] = useState<FieldType[]>([])
	const [jobFilter, setJobFilter] = useState<IJobFiter>()

	const getSubProcessesName = (processIds: number[]) => {
		ProcessService.getSubProcessesName(processIds).then(result => {
			setSubProcesses(result.results.map((r: any) => ({...r, checked: false})))
		})
	}

	const getFieldsNames = (subprocessIds: number[]) => {
		FieldsService.getFieldsNames(subprocessIds).then((result: any) => {
			setFields(result.results.map((r: any) => ({...r, checked: false})))
		})
	}

	const onProcessClick = (processes: {id: number, name:string}[]) => {
		const processIds = processes.map(p =>p.id)
		const newJobFilter: IJobFiter = {
			...jobFilter,
			processIds
		}
		setJobFilter(newJobFilter)
		getSubProcessesName(processIds)
	}

	const onSubprocessClick = (subprocesses: {id: number, name:string}[]) => {
		const subprocessIds = subprocesses.map(p =>p.id)
		const newJobFilter: IJobFiter = {
			...jobFilter,
			subprocessIds
		}
		getFieldsNames(subprocessIds)
		setJobFilter(newJobFilter)
	}

	const onFieldsClick = (fields: any) => {
		// console.log(fields)
	}

	const onChangeStartDate = (date: Date | null | undefined) => {
		const newJobFilter: IJobFiter = {
			...jobFilter,
			startDate: date?.toISOString()
		}
		setJobFilter(newJobFilter)
	}

	const onChangeEndDate = (date: Date | null | undefined) => {
		const newJobFilter: IJobFiter = {
			...jobFilter,
			endDate: date?.toISOString()
		}
		setJobFilter(newJobFilter)
	}

	const onChangeJobId = (event: any, data: any) => {
		const newJobFilter: IJobFiter = {
			...jobFilter,
			jobIds: data.value? [data.value]: []
		}
		setJobFilter(newJobFilter)
	}

	const onApplyFilters = () => {
		props.onApplyFilters(jobFilter)
	}

	const onClearFilters = () => {
		setJobFilter({})
		setProcesses(processes.map(process => ({...process, checked: false})))
		setSubProcesses(subprocesses.map(subprocess => ({...subprocess, checked: false})))
		setFields(fields.map(field => ({...field, checked: false})))
		props.onApplyFilters({})
	}

	return (
		<div className="TodoTabFilter" data-testid="TodoTabFilter">
			<form action="">
				<div className='FormFilterLayout'>
					<div className='FieldsContainer'>
						<Field label="Processes">
							<MultiTagCombobox resultName='process' results={processes} onResultClick={onProcessClick}/>
						</Field>
						<Field label="SubProcesses">
							<MultiTagCombobox resultName='subprocess' results={subprocesses} onResultClick={onSubprocessClick}/>
						</Field>
						<Field label="Start Date">
							<DatePicker
								size='large'
								placeholder="Start Date..."
								onSelectDate={onChangeStartDate}
								value={jobFilter?.startDate ? new Date(jobFilter.startDate): null}
							/>
						</Field>
						<Field label="End Date">
							<DatePicker
								size='large'
								placeholder="End Date..."
								onSelectDate={onChangeEndDate}
								value={jobFilter?.endDate ? new Date(jobFilter.endDate): null}
							/>
						</Field>

					</div>
					<div className='LastFromRow'>
						<Field
							label="Job Id"
						>
							<Input size='large'
								onChange={onChangeJobId}
								value={jobFilter?.jobIds?.length? jobFilter.jobIds.join(','): '' }
							/>
						</Field>
						<Field label="Fields">
							<MultiTagCombobox resultName='field' results={fields} onResultClick={onFieldsClick}/>
						</Field>
						<Field
							label="Record Value"
						>
							<Input size='large'/>
						</Field>
					</div>
				</div>
				<div className='ButtonsContainer'>
					<Button
						onClick={onApplyFilters}
						appearance='primary'
					>
						Apply Filters
					</Button>
					{/* <Button>Save Filter</Button> */}
					<Button onClick={onClearFilters}>Clear Filters</Button>
					{/* <Button>Saved Filters</Button> */}
				</div>
			</form>

		</div>
	)
};

export default TodoTabFilter;