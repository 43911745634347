import { createContext, useContext, useEffect, useState } from "react";
import { AuthService } from "../services/AuthService";
import { http } from "../utils/http-common";

export const AuthContext = createContext({
    isAuthenticated: false,
    login: async (apiToken: string): Promise<boolean>=>false,
    logout: ():void=>{},
    checkIfAutheticated: async (): Promise<boolean> => false
})

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({children} : any) => {

    const [isAuthenticated, setIsAuthenticated] = useState(false)

    useEffect(() => {
        checkIfAutheticated().then(result => setIsAuthenticated(result))
    }, [])

    const checkIfAutheticated = async (): Promise<boolean> => await AuthService.isAuthenticated()

    const login = async (apiToken: string): Promise<boolean> => {
        const loginResult = await AuthService.login(apiToken)
        setIsAuthenticated(loginResult)

        return loginResult
    };

    const logout = () => {
        console.log('loggin out...')
        setIsAuthenticated(!AuthService.logout())
    }

    const value = {isAuthenticated, login, logout, checkIfAutheticated};

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}