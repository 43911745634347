import { IFilters } from "../interfaces/IFilters"
import { IPageConfig } from "../interfaces/IPageConfig"
import { EDITING_TYPE, ERROR_TYPE, UNPROCESSED_TYPE } from "./constants"
import { IFieldDetail } from "../interfaces/IFIeldDetail"

export type ApiResult<T> = {
    results: T[],
    count: number
}

export function convertResponse<T>(promise: Promise<T>): Promise<ApiResult<T>> {
    return promise.then((result: any) => {
        return {
            results: result.data.results as T[],
            count: result.data.count
        } as ApiResult<T>
    }).catch((error: any) => {
        return {
            results: [],
            count: 0
        } as ApiResult<T>
    })
}

export function convertFilters(pageConfig?: IPageConfig, type?: "ERROR" | "WAITING", filters?: IFilters) {
    let allFilters = '';
    let statusses = type === ERROR_TYPE? [ERROR_TYPE, EDITING_TYPE]: [ERROR_TYPE, UNPROCESSED_TYPE]
    if(pageConfig?.limit)
        allFilters += `&limit=${pageConfig.limit}`;
    if(pageConfig?.offset)
        allFilters += `&offset=${pageConfig.offset}`;
    if(type)
        allFilters += `&statuses=${statusses}`;
    if(filters?.processIds?.length)
        allFilters += `&processIds=${filters.processIds.join(',')}`;
    if(filters?.subprocessIds?.length)
        allFilters += `&subprocessIds=${filters.subprocessIds.join(',')}`;
    if(filters?.startDate)
        allFilters += `&startDate=${filters.startDate}`;
    if(filters?.endDate)
        allFilters += `&endDate=${filters.endDate}`;
    if(filters?.jobIds?.length)
        allFilters += `&jobIds=${filters.jobIds.join(',')}`;
    if(filters?.workTemplate)
        allFilters += `&workTemplate=${filters.workTemplate}`
    if(filters?.jobStatus)
        allFilters += `&jobStatus=${filters.jobStatus}`

    return allFilters
}

export function dynamicObjectToFieldConvertor(data: any): IFieldDetail[] {
    const fixedCodeTextAreas = [
        'Mensagem', 'Mensagem RPA'
    ]

    const fields:IFieldDetail[] = [];
    Object.keys(data).forEach((key) => {
        const field = data[key];
        if(!field.isActive)
            return;
        const newField = {
            label: field.isActive? field.code: `${field.code} (inactive)`,
            type: fixedCodeTextAreas.includes(field.code)? 'textarea' :field.type,
            value: field.value
        } as IFieldDetail

        fields.push(newField);
    })

    return fields;
}