import { FC, useContext } from 'react';
import './ToolbarApp.scss';
import { AuthContext } from '../../contexts/AuthContext';
import { Button } from '@fluentui/react-components';
import { useNavigate, useParams } from 'react-router-dom';

interface ToolbarAppProps { }

const ToolbarApp: FC<ToolbarAppProps> = () => {
	const { logout } = useContext(AuthContext)
	const navigate = useNavigate()
	const { jobId, todoStatus } = useParams()

	const onLogout = () => {
		logout()
		navigate('/login')
	}

	const backHome = () => {
		navigate('/')
	}

	const BackToTask = () => {
		return (jobId && todoStatus) ?
			<Button onClick={backHome} appearance='primary'>Back to Tasks</Button> : <div></div>
	}

	return (
		<div className="ToolbarApp" data-testid="ToolbarApp">
			<BackToTask />
			<Button onClick={onLogout}>Log out</Button>
		</div>
	)
};

export default ToolbarApp;
