import { useState } from "react";
import { IFieldDetail } from "../interfaces/IFIeldDetail";
import { dynamicObjectToFieldConvertor } from "../utils/helpers";

export function useDynamicFieldConvertion() {

    const [fields, setFields] = useState<IFieldDetail[]>([])

    const dynamicConvertion = (dynamicRecord: any) => {
        const dynamicFields = dynamicObjectToFieldConvertor(dynamicRecord)
        setFields(dynamicFields)

        return dynamicFields
    }

    const changeFieldsValue = (key: any, value: any) => {
        const updatedFields = fields.map((field: IFieldDetail) => {
            if(field.label === key)
                return {...field, value}
            return field
        })
        setFields(updatedFields)
    }

    return {fields, dynamicConvertion, changeFieldsValue}
}