import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { Spinner } from '@fluentui/react-components';

interface ProtectedRouteProps {
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = () => {
	const { isAuthenticated, checkIfAutheticated } = useContext(AuthContext);
	const [authenticated, setAuthenticated] = useState<boolean>(isAuthenticated);
	const [loading, setLoading] = useState<boolean>(true)
	const navigate = useNavigate()
	const {jobId, todoStatus} = useParams()

	useEffect(() => {
		if(isAuthenticated)
			setLoading(false)
		else{
			checkIfAutheticated().then(result => {
				setAuthenticated(result)
				setLoading(false)
				if (result){
					if(jobId && todoStatus)
						navigate(`/${jobId}/${todoStatus}`)
					else
						navigate('/')
				}


			})
		}
	}, [])

	return loading ? <Spinner/> : authenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;