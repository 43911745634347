import { FC, useEffect, useState } from 'react';
import './TodoDetailsView.scss';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleData, AccordionToggleEvent, AccordionToggleEventHandler, Button, Card, Field, Input, Spinner, Table, TableBody, TableCell, TableCellLayout, TableHeader, TableHeaderCell, TableRow, TableSelectionCell } from '@fluentui/react-components';
import { Mapper } from '../../utils/mapper';
import RecordService from '../../services/RecordService';
import { useNavigate, useParams } from 'react-router-dom';
import { useDynamicFieldConvertion } from '../../hooks/UseDynamicFieldConvertion';
import DynamicRecordForm from '../../components/DynamicRecordForm/DynamicRecordForm';
import AppBreadcrumb from '../../components/AppBreadcrumb/AppBreadcrumb';
import { CANCEL_ACTION, ERROR_TYPE, INVALIDATE_ACTION, SUBMIT_ACTION, VALIDATE_ACTION, WAITING_TYPE } from '../../utils/constants';
import ToolbarApp from '../../components/ToolbarApp/ToolbarApp';
import { RecordsDataSelectable } from '../../types/RecordsDataSelectable';
import JobsService from '../../services/JobsService';
import { IRecordsData } from '../../interfaces/IRecordsData';
import {
	SearchRegular,
} from "@fluentui/react-icons";
import { IFieldDetail } from '../../interfaces/IFIeldDetail';

interface TodoDetailsProps { }

interface IOutputRecord {
	id: number;
	data: IFieldDetail[]
}

const TodoDetails: FC<TodoDetailsProps> = () => {
	const {jobId, todoStatus } = useParams()
	const [recordsPerJob, setRecordsPerJob] = useState<Array<RecordsDataSelectable>>([])
	const [selectedRecord, setSelectedRecord] = useState<IRecordsData>();
	const {fields: inputFields, dynamicConvertion: dynamicConversionInputs, changeFieldsValue } = useDynamicFieldConvertion()
	const {dynamicConvertion: dynamicConversionOutputs } = useDynamicFieldConvertion()
	const [observations, setObservations] = useState<string>("")
	const [outputRecords, setOutputRecords] = useState<IOutputRecord[]>([])
	const allCheck = recordsPerJob.filter(r => r.selected).length === recordsPerJob.length
	const someCheck = recordsPerJob.filter(r => r.selected).length > 0
	const navigate = useNavigate()
	const [query, setQuery] = useState<string>("")
	const [loading, setLoading] = useState<boolean>(false)

	const recordsFiltered = recordsPerJob.filter((record: RecordsDataSelectable) => record.id.toString().includes(query))
	const columns = [
		{ columnKey: 'status', label: 'STATUS' },
		{ columnKey: 'id', label: 'ID' }
	]

	const [openItems, setOpenItems] = useState<number[]>();

	useEffect(() => {
		getRecords()
	}, [])

	const onRecordClick = (recordId: number) => {
		const updatedRecords = recordsPerJob.map(
			(record: RecordsDataSelectable) => record.id === recordId ? ({ ...record, selected: !record.selected }) : ({ ...record, selected: false }))
		setRecordsPerJob(updatedRecords)
		const currentRecord = updatedRecords.find(f => f.id === recordId)
		if (currentRecord?.selected) {
			setSelectedRecord(currentRecord)
			getRecordsByInputRecord(currentRecord.id)

			if (currentRecord.data)
				dynamicConversionInputs(currentRecord.data)
		}
		else {
			setSelectedRecord(undefined)
			setOutputRecords([])
			dynamicConversionInputs({})
		}
	}

	const handleToggle: AccordionToggleEventHandler<number> = (event: AccordionToggleEvent, data: AccordionToggleData<number>) => {
		setOpenItems(data.openItems);
	};

	const handleCollapseEnableAll = (collapse: boolean) => {
		if(collapse)
			setOpenItems([])
		else {
			setOpenItems(outputRecords.map((record: IOutputRecord) => record.id))
		}
	}

	const onCheckboxClick = (event: any, recordId: number) => {
		event.stopPropagation()
		const updatedRecords = recordsPerJob.map(
			(record: RecordsDataSelectable) => record.id === recordId ? ({ ...record, selected: !record.selected }) : record)
		setRecordsPerJob(updatedRecords)
		setSelectedRecord(undefined)
		dynamicConversionInputs({})
	}

	const onHeaderSelected = () => {
		const changedStatus = !allCheck;
		setRecordsPerJob(
			recordsPerJob.map(record => ({ ...record, selected: changedStatus }))
		)
		if (!changedStatus)
			dynamicConversionInputs({})
	}

	const getRecords = () => {
		const currentStatus = todoStatus === ERROR_TYPE || todoStatus === WAITING_TYPE ? todoStatus : ERROR_TYPE
		RecordService.getRecordsByJobId([Number(jobId)], currentStatus).then((result: any) => {
			if (!result.results.length)
				navigate("/")

			const recordValues = Mapper.mapToRecordData(result.results)
			const records = recordValues.map((recordData: IRecordsData) => ({ ...recordData, selected: false, collapsed: true }))
			setRecordsPerJob(records)
			setLoading(false)
		})
	}
	const getRecordsByInputRecord = (inputRecord: number) => {
		RecordService.getOutputRecordsByInputRecord(inputRecord).then((result: any) => {
			if(result?.results?.length){
				const allResults = result.results;
				const outputRecords = allResults.map((result: any) => {
					const flds = dynamicConversionOutputs(result.data)
					return {id: result.id, data: flds}
				})
				if(outputRecords){
					setOutputRecords(outputRecords)
				}
			}
		})
	}

	const onSearchRecord = (query: string) => {
		setQuery(query)
	}

	const onSave = () => {
		setLoading(true)
		let fieldsToSave: any = {}
		inputFields.forEach(field => {
			fieldsToSave[field.label] = field.value
		})
		if (selectedRecord) {
			RecordService.editRecordsData(selectedRecord.id, fieldsToSave).then((result: any) => {
				getRecords()
			})
		}
	}

	const onCancel = () => {
		if (selectedRecord) {
			dynamicConversionInputs(selectedRecord.data)
		}
	}

	const onChange = (event: any, data: any) => {
		setObservations(data.value)
	}

	const onValidInvalid = (action: string): void => {
		const obs: { [key: number]: string } = {}
		const idx = selectedRecord?.id ?? 0
		obs[idx] = observations
		setLoading(true)
		RecordService
			.validateInvalidateRecords(action, [idx], obs)
			.then(result => {
				getRecords()
			})
	}

	const onCancelJob = () => {
		JobsService.cancelJob(Number(jobId)).then(result => {
			navigate("/")
		})
	}

	const onCancelSelected = () => {
		const selectedRowsIds = recordsPerJob
			.filter(record => record.selected)
			.map(record => record.id)

		RecordService
			.validateInvalidateRecords(CANCEL_ACTION, selectedRowsIds)
			.then(result => {
				getRecords()
			})
	}

	const onSubmitSelected = () => {
		const selectedRowsIds = recordsPerJob
			.filter(record => record.selected)
			.map(record => record.id)

		RecordService
			.validateInvalidateRecords(SUBMIT_ACTION, selectedRowsIds, {})
			.then(result => {
				getRecords()
			})
	}

	function ButtonsByView() {
		if (todoStatus !== WAITING_TYPE) {
			return (
				<div className='TodoDetailsButtons'>
					<Button onClick={() => onCancel()}>Cancel</Button>
					<Button onClick={onSave} appearance='primary'>Save</Button>
					{loading && <Spinner/>}
				</div>
			)
		}
		else {
			return (
				<div className='TodoDetailsButtons'>
					<Button onClick={() => onCancel()}>Cancel</Button>
					<Button onClick={() => onValidInvalid(INVALIDATE_ACTION)} appearance='primary'>Invalidate</Button>
					<Button onClick={() => onValidInvalid(VALIDATE_ACTION)} appearance='primary'>Validate</Button>
					{loading && <Spinner/>}
				</div>
			)
		}
	}

	function CollapseExpand(){
		if(openItems?.length)
			return <div className='ExpandCollapseText' onClick={() => handleCollapseEnableAll(true)}>Collapse All</div>
		else
			return <div className='ExpandCollapseText' onClick={() => handleCollapseEnableAll(false)}>Expand All</div>
	}

	function OutputCardView() {
		if(outputRecords?.length){
			return (
				<Card>
					<Accordion
						multiple
						collapsible
						openItems={openItems}
						onToggle={handleToggle}
					>
						<div className='OutputHeaderContainer'>
							<div className='OutputHeaderTitle'>Output Record List ({outputRecords.length})</div>
							<CollapseExpand/>
						</div>
						{outputRecords.map((record: IOutputRecord) => {
							return (
								<AccordionItem className='OutputItemContainer' value={record.id} key={record.id}>
									<AccordionHeader>Output Record #{record.id}</AccordionHeader>
									<AccordionPanel>
										{record?.data?.length && record.data.map((field: IFieldDetail) => {
											return (
												<div className='FieldContainer' key={field.label}>
													<div className='FieldLabel'>{field.label}:</div>
													<div className='FieldValue'>{field.value}</div>
												</div>
											)
										})}
									</AccordionPanel>
								</AccordionItem>
							)
						})}
					</Accordion>
				</Card>
			)
		}
		else{
			return <></>
		}
	}

	return (
		<>
			<ToolbarApp />
			<div className="TodoDetails" data-testid="TodoDetails">
				<Card className='TodoDetailsCard2'>
					<div className='TodoDetailsHeader'>
						<h3>Job #{jobId} Records</h3>
					</div>
					<div className='TodoDetailsBody'>

						{todoStatus !== WAITING_TYPE && <div className='TodoDetailsButtons'>
							<Button appearance='secondary' onClick={onCancelJob}>Cancel Job</Button>
							<Button disabled={!someCheck} onClick={onCancelSelected} appearance='secondary'>Cancel Selected</Button>
							<Button disabled={!someCheck} onClick={onSubmitSelected} appearance='primary'>Submit Selected</Button>
						</div>}
						<Input className='SearchInput' placeholder='Search' size='large' onChange={(ev, data) => onSearchRecord(data.value)}
							contentAfter={<SearchRegular />}
						/>
						<Table>
							<TableHeader className='TableHeader'>
								<TableRow onClick={onHeaderSelected}>
									<TableSelectionCell
										checked={allCheck}
										checkboxIndicator={{ "aria-label": "Select all rows " }}
									/>
									{columns.map((column) => (
										<TableHeaderCell className='HeaderCell' key={column.columnKey}>
											{column.label}
										</TableHeaderCell>
									))}
								</TableRow>
							</TableHeader>
							<TableBody>
								{recordsFiltered.map(record => (
									<TableRow key={record.id} onClick={() => onRecordClick(record.id)}>
										<TableSelectionCell
											onClick={(event) => onCheckboxClick(event, record.id)}
											checked={record.selected}
											checkboxIndicator={{ "aria-label": "Select row" }}
										/>
										<TableCell>
											<TableCellLayout>
												<div className='StatusStyle'>
													{record.status}
												</div>
											</TableCellLayout>
										</TableCell>
										<TableCell>
											<TableCellLayout>
												{record.id}
											</TableCellLayout>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</Card>
				<Card className='TodoDetailsCard'>
					<div className='TodoDetailsHeader'>
						{selectedRecord && <h3>Input Record #{selectedRecord.id}</h3>}
					</div>
					<div className='TodoDetailsBody'>
						<div>
							{inputFields.length > 0 && <ButtonsByView />}
							<DynamicRecordForm fields={inputFields} changeFieldsValue={changeFieldsValue} onCancel={onCancel} onSave={onSave} />
							{inputFields.length > 0 && todoStatus === WAITING_TYPE &&
								<Field label="Observations">
									<Input size='large' value={observations} onChange={onChange}></Input>
								</Field>
							}
						</div>
					</div>
				</Card>
				<Card className='TodoDetailsCard'>
					<div className='TodoDetailsBody'>
						<OutputCardView/>
					</div>
				</Card>
			</div>
		</>
	)
};

export default TodoDetails;
