import axios from "axios";
import { AuthService } from "../services/AuthService";

export const http = axios.create({
	baseURL: "https://beta.imaestro.dev/api_v3",
	headers: {
		"Content-type": "application/json"
	}
});

http.interceptors.request.use(function (config) {
	const token = AuthService.getItem("ApiToken")
	if(token)
		config.headers["User-Token"] = token;

	return config;
});
