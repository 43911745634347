import { http } from '../utils/http-common'
import { IJob } from '../interfaces/IJob'
import { IPagintatedData } from '../interfaces/IPaginatedData'
import { ApiResult, convertResponse } from '../utils/helpers'
import { IPageConfig } from '../interfaces/IPageConfig'
import { CANCEL_ACTION } from '../utils/constants'

const getAll = (pageConfig: IPageConfig, type?: "ERROR" | "WAITING", filters?: any) => {

    const convertedFilters = convertFilters(pageConfig, type, filters)
    return convertResponse<any>(http.get(`/jobs?${convertedFilters}`))
}

const convertFilters = (pageConfig: IPageConfig, type?: "ERROR" | "WAITING", filters?: any) => {
    let allFilters = '';
    if(pageConfig.limit)
        allFilters += `&limit=${pageConfig.limit}`;
    if(pageConfig.offset)
        allFilters += `&offset=${pageConfig.offset}`;
    if(type)
        allFilters += `&status=${type}`;
    if(filters?.process_ids?.length)
        allFilters += `&processIds=${filters.process_ids.join(',')}`;
    if(filters?.subprocess_ids?.length)
        allFilters += `&subprocessIds=${filters.subprocess_ids.join(',')}`;
    if(filters?.start_date)
        allFilters += `&startDate=${filters.start_date}`;
    if(filters?.end_date)
        allFilters += `&endDate=${filters.end_date}`;

    return allFilters
}

export const getPaginationFromResult = <T>(apiResult: ApiResult<T>, pageConfig: IPageConfig): IPagintatedData => {
    return {
        rowsPerPage: pageConfig.limit,
        totalPages: Math.ceil(apiResult.count / pageConfig.limit),
        data: apiResult.results as T[]
    }
}

const getById = (id: number) => {
    return http.get<IJob>(`jobs/${id}`)
}

const update = (id: number, newData: IJob) => {
    return http.put<IJob>(`jobs/${id}`, newData)
}

const cancelJob = (jobId: number) => {
    return http.post(`jobs/${jobId}/triggerAction`, {action: CANCEL_ACTION})
}

const JobsService = {
    getAll,
    getById,
    update,
    cancelJob
}

export default JobsService
