import {http} from '../utils/http-common'
import { IRecord } from '../interfaces/IRecord'
import { ApiResult, convertFilters, convertResponse } from '../utils/helpers'
import { IPageConfig } from '../interfaces/IPageConfig'
import { IFilters } from '../interfaces/IFilters'
import { AxiosResponse } from 'axios'
import {OUTPUT_DATA_BY_INPUT_RECORD} from '../utils/constants_tests'
const getAll = (pageConfig: IPageConfig, type?: "ERROR" | "WAITING", filters?: IFilters): Promise<ApiResult<any>> => {
    const convertedFilters = convertFilters(pageConfig, type, filters)
    return convertResponse<any>(http.get(`/records?${convertedFilters}`))
}

const getById = (id: number): Promise<AxiosResponse<IRecord, any>> => {
    return http.get<IRecord>(`records/${id}`)
}

const getOutputRecordsByInputRecord = (recordId: number, mock = false): any => {
    if(mock){
        return new Promise((resolve, reject) => {
            resolve(OUTPUT_DATA_BY_INPUT_RECORD)
        })
    }
    return convertResponse<any>(http.get<any>(`/records/${recordId}/outputRecords`))
}

const getRecordsByJobId = (jobId: number[], type: "ERROR" | "WAITING"): Promise<ApiResult<any>> => {
    const convertedFilters = convertFilters(undefined, type, {jobIds: jobId} as IFilters)
    return convertResponse<any>(http.get(`/records?${convertedFilters}`))
}

const editRecordsData = (recordId: number, data: unknown): any => {
    return http.patch(`/records/${recordId}/data`, data)
}

const validateInvalidateRecords = async (action: string, recordIds: number[], observations?: any) => {
    const actions: any = {}
    recordIds.forEach((recordId: number) => {
        const observation = observations? observations[recordId]: ''
        actions[recordId] = {action: action, observation}
    });

    try{
        return http.post(`/records/triggerActionsBulk`, {actions})
    }
    catch(error){
        console.error(error)
    }
}

// const validateInvalidateRecords = async (action: string, recordIds: number[], observations?: any) => {

//     const requests = recordIds.map((recordId: number) => {
//         const observation = observations? observations[recordId]: ''
//         return http.post(`records/${recordId}/triggerAction`, {action, observation})
//     });

//     try{
//         const response = Promise.all(requests);
//         return response
//     }
//     catch(error){
//         console.error(error)
//     }
// }

const RecordService = {
    getAll,
    getById,
    getRecordsByJobId,
    validateInvalidateRecords,
    editRecordsData,
    getOutputRecordsByInputRecord
}

export default RecordService