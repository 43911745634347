import { IJob } from "../interfaces/IJob"
import { IRecord } from "../interfaces/IRecord"
import { IRecordsData } from "../interfaces/IRecordsData"

const mapToJobData = (data: IJob[]) => {
    return data.map((job: IJob) => ({
        created_on: job.createdOn,
        process: job.process.name,
        subprocess: job.subprocess.name,
        job_id: job.id,
        error_records: job.nrInputRecords
    }))
}

const mapToRecordData = (data: IRecord[]): IRecordsData[] => {
    return data.map((record: IRecord) => {
        return ({
            created_on: record.createdOn,
            job_id: record.job.id,
            process: record.process.name,
            subprocess: record.subprocess.name,
            id: record.id,
            status: record.status,
            data: record.data
        })
    })
}

const mapToRecordDataDetail = (data: IRecord[]) => {
    return data.map((record: IRecord) => {
        return ({
            status: record.status,
            id: record.id
        })
    })
}

export const Mapper = {
    mapToJobData,
    mapToRecordData,
    mapToRecordDataDetail
}