import React, { Component, useContext } from 'react';
import logo from './logo.svg';
import './App.scss';
import TodoTabsView from './views/TodoTabsView/TodoTabsView';
import TodoDetails from './views/TodoDetailsView/TodoDetailsView';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import AuthPage from './components/AuthPage/AuthPage';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import TodoDetailsView from './views/TodoDetailsView/TodoDetailsView';

function App() {
	return (
		<AuthProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/login" element={<AuthPage />} />
					<Route path="/" element={<ProtectedRoute />}>
						<Route path="/" element={<TodoTabsView />} />
						<Route path="/:jobId/:todoStatus" element={<TodoDetailsView />} />
					</Route>
				</Routes>
			</BrowserRouter>
		</AuthProvider>
	);
}

export default App;
