import React, { FC } from 'react';
import './GenericDialogContainer.scss';
import { Dialog, DialogTrigger, Button, DialogSurface, DialogBody, DialogTitle, DialogContent, makeStyles, shorthands, DialogActions } from '@fluentui/react-components';

const useStyles = makeStyles({
	dialog: {
		width: "80vw",
		...shorthands.borderRadius("1rem"),

	},

	title: {
		paddingBottom: "1rem"
	},

	dialogContent: {
		maxHeight: "35rem",
	}
});

export interface ITriggerProps {
	isDisabled: boolean;
	appearance: any;
	text: string;
	icon: any;
}

interface GenericDialogContainerProps {
	dialogName: string;
	TemplateBody: any;
	TemplateActions: any;
	TriggerProps: ITriggerProps;
}

const GenericDialogContainer: FC<GenericDialogContainerProps> = (props) => {
	const styles = useStyles();
	return (
		<Dialog>
			<DialogTrigger disableButtonEnhancement>
				<Button disabled={props.TriggerProps?.isDisabled} appearance={props.TriggerProps?.appearance} icon={props.TriggerProps.icon && <props.TriggerProps.icon />}>{props.TriggerProps.text}</Button>
			</DialogTrigger>
			<DialogSurface className={styles.dialog}>
				<DialogBody>
					<DialogTitle className={styles.title}>{props.dialogName}</DialogTitle>
					<DialogContent className={styles.dialogContent}>
						<props.TemplateBody/>
					</DialogContent>
					<DialogActions>
						<props.TemplateActions/>
					</DialogActions>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	);
}


export default GenericDialogContainer;
