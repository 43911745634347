import { INamedEntity } from "../interfaces/INamedEntity"
import { ApiResult, convertResponse } from "../utils/helpers"
import {http} from '../utils/http-common'



const getProcessesNames = (): Promise<ApiResult<Array<INamedEntity>>> => {

    //     const mockPromise: Promise<Array<INamedEntity>> = new Promise((resolve, reject) => {
    //         const processes = JOBS_DATA.map((job: IJob) => ({
    //             name: job.process,
    //             id: job.process_id
    //         })).reduce<INamedEntity[]>((acc: INamedEntity[], curr) => {
    //             if(!acc.map(a => a.id).includes(curr.id))
    //                 acc.push(curr)
    //             return acc
    //         },[])
    //         resolve(processes)
    //     })
    //     return mockPromise
    // }
    return convertResponse<Array<INamedEntity>>(http.get(`/processes`))
}

const getSubProcessesName = (process_ids: number[]) => {
    // if(true){
    //     const mockPromise: Promise<Array<INamedEntity>> = new Promise((resolve, reject) => {
    //         const processes = JOBS_DATA.map((job: IJob) => ({
    //          name: job.subprocess,
    //          id: job.subprocess_id
    //         })).reduce<INamedEntity[]>((acc, curr) => {
    //             if(!acc.map(a => a.id).includes(curr.id))
    //                 acc.push(curr)
    //             return acc;
    //         }, [])
    //         resolve(processes)
    //     })
    //     return mockPromise
    // }
    let strFilter = '';
    if(process_ids?.length)
        strFilter = `processIds=${process_ids.join(',')}`
    return convertResponse<Array<INamedEntity>>(http.get(`/subprocesses?${strFilter}`))

}



export const ProcessService = {
    getProcessesNames,
    getSubProcessesName
}

