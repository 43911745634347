import React, { FC, useEffect, useState } from 'react';
import './MultiTagCombobox.scss';
import { Button, Combobox, ComboboxProps, useComboboxFilter, useId, Option, makeStyles } from '@fluentui/react-components';
import {
	Dismiss12Regular
  } from "@fluentui/react-icons";
import { NamedEntityCheckType } from '../../types/NamedEntityCheckType';

interface MultiTagComboboxProps {
	results: NamedEntityCheckType[];
	resultName: string;
	onResultClick: any
}
const useStyles = makeStyles({
	listbox: {
	  maxHeight: "250px",
	},
	option: {
	  height: "32px",
	},
});

const MultiTagCombobox: FC<MultiTagComboboxProps> = (props) => {

	useEffect(() => {
		setValueOptions(props.results)
		setMatchingOptions(props.results)
	}, [props.results])

	const comboId = useId("combo-multi");
	const [value, setValue] = React.useState("");
	const [valueOptions, setValueOptions] = useState<NamedEntityCheckType[]>([])
	const [matchingOptions, setMatchingOptions] = React.useState<NamedEntityCheckType[]>(valueOptions);

	const selectedOptions = valueOptions.filter((option: NamedEntityCheckType) => option.checked)

	const styles = useStyles();

	const onChange: ComboboxProps["onChange"] = (event) => {
		const value = event.target.value.trim();
		const matches = props.results.filter(
		  (option) => option.name.toLowerCase().includes(value.toLowerCase())
		);

		setMatchingOptions(matches);
		setValue(value)
	};

	const onTagClick = (option: NamedEntityCheckType) => {
		updateSelections(option)
	};

	function updateSelections(selectedOpt: NamedEntityCheckType){

		const updateOptionSelection = valueOptions
			.map((option: NamedEntityCheckType) => option.id === selectedOpt.id? ({...option, checked: !option.checked}): option)
		setValueOptions(updateOptionSelection)
		props.onResultClick(updateOptionSelection.filter(o => o.checked))

	}

	const onSelect: ComboboxProps["onOptionSelect"] = (event, data) => {
		if(data.optionText){
			const selectedOpt = valueOptions.find(s => s.name === data.optionText)
			if(selectedOpt){
				updateSelections(selectedOpt)
			}
		}
	};

	const onFocus = () => {
		setValue("");
		setMatchingOptions(valueOptions);
	};

	const onBlur = () => {
		setValue(selectedOptions.map(s => s.name).join("; "));
	};

	return (
		<>

			<Combobox
				multiselect={true}
				freeform
				placeholder={`Select a ${props.resultName}`}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				onFocus={onFocus}
				selectedOptions={selectedOptions.map(s => s.name)}
				onOptionSelect={onSelect}
				listbox={{ className: styles.listbox }}
			>
				{matchingOptions.map((option) => (
					<Option key={option.id}>{option.name}</Option>
				))}
			</Combobox>
		</>
  )
};

export default MultiTagCombobox;
