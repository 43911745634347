import React, { FC } from 'react';
import './GenericDialog.scss';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, makeStyles, shorthands } from '@fluentui/react-components';

const useStyles = makeStyles({
	dialog: {
	  width: "80vw",
	  ...shorthands.borderRadius("1rem"),

	},

	title: {
	  paddingBottom: "1rem"
	},

	dialogContent: {
	  maxHeight: "35rem",
	}
});

interface GenericDialogProps {
  	dialogName: string;
	TemplateComponent: any;
	TemplateButtonIcon: any;
	TemplateButtonText: string;
	templateProps: any;
	TemplateButtonProps: any;
}

const GenericDialog: FC<GenericDialogProps> = ({TemplateComponent, TemplateButtonText, TemplateButtonIcon, TemplateButtonProps, templateProps, dialogName}) => {
	const [open, setOpen] = React.useState(false)

	const onCancel = (event: any) => {
		templateProps.onCancel()
		setOpen(false)
		event.stopPropagation()
	}

	const onInvalidate = (observations: string) => {
		templateProps.onInvalidate(observations)
		setOpen(false)
	}

	const onValidate = (observations: string) => {

		templateProps.onValidate(observations)
		setOpen(false)
	}

	const onSave = (fields: any) => {
		templateProps.onSave(fields)
		setOpen(false)
	}

	const props = {
		...templateProps,
		onCancel,
		onSave,
		onValidate,
		onInvalidate
	}

	const styles = useStyles();
	return (
		<Dialog open={open} onOpenChange={(event, data) => setOpen(data.open)}>
			<DialogTrigger disableButtonEnhancement>
				<Button disabled={TemplateButtonProps?.isDisabled} appearance={TemplateButtonProps?.appearance} onClick={() => TemplateButtonProps?.onClick()} icon={TemplateButtonIcon && <TemplateButtonIcon />}>{TemplateButtonText}</Button>
			</DialogTrigger>
			<DialogSurface className={styles.dialog}>
				<DialogBody>
					<DialogTitle className={styles.title}>{dialogName}</DialogTitle>
					<DialogContent className={styles.dialogContent}>
						<TemplateComponent {...props}/>
					</DialogContent>
				</DialogBody>
			</DialogSurface>
		</Dialog>
	)
};

export default GenericDialog;
