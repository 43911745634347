import { INamedEntity } from "../interfaces/INamedEntity"
import { convertResponse } from "../utils/helpers"
import { http } from "../utils/http-common"

const getFieldsNames = (subprocess_ids: number []) => {
    let strFilter = ''
    if(subprocess_ids?.length)
        strFilter = `subprocessIds=${subprocess_ids.join(',')}`
    return convertResponse<Array<INamedEntity>>(http.get(`/fields?${strFilter}`))
}

export const FieldsService = {
    getFieldsNames
}