
import { error } from "console";
import { http } from "../utils/http-common"

const setItem = <T>(key: string, value: T): void => {
    const serializedValue = JSON.stringify(value);
    window.localStorage.setItem(key, serializedValue);
};

const getItem = <T>(key: string): T | null => {
    const serializedValue = window.localStorage.getItem(key);
    return serializedValue ? (JSON.parse(serializedValue) as T) : null;
};

const removeItem = (key: string): void => {
    window.localStorage.removeItem(key);
};

const isLoggedIn = async (apiToken: string): Promise<boolean> => {
    const response = http.get(`/processes?limit=10&offset=0`, {headers: {
        "User-Token": apiToken
    }})
    return response
        .then(result => result.status === 200)
        .catch(error => false)

}

const isAuthenticated = async () => {
    const apiTokens = getItem<string>("ApiToken")
    if(!apiTokens)
        return false

    return await isLoggedIn(apiTokens)
}

const login = async (apiToken: string) => {
    removeItem("ApiToken")
    const isLogged = await isLoggedIn(apiToken)
    if(isLogged){
        setItem<string>("ApiToken", apiToken)
        return true;
    }
    else{
        return false
    }
}

const logout = () => {
    removeItem("ApiToken")
    removeItem("SessionToken")
    return true
}

export const AuthService = {
    isAuthenticated,
    login,
    logout,
    getItem
}