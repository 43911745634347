import * as React from "react";
import { usePagination, DOTS } from "./usePagination";
import "./Pagination.scss";
import { Badge, Button } from "@fluentui/react-components";
import { CaretLeft24Regular, CaretRight24Regular } from "@fluentui/react-icons";

export interface IProps {
    onPageChange: (page: number) => void;
    totalCount: number;
    siblingCount : number;
    rowsPerPage: number;
    className:string;
    currentPage: number;
}

export const Pagination : React.FC<IProps> = (props) => {

    const paginationRange = usePagination({...props});

    const onNext = () => {
        let lastPage = 1
        const paginationLenght = paginationRange?.length
        if (paginationLenght)
            lastPage = paginationRange[paginationLenght -1]
        if(props.currentPage < lastPage)
            props.onPageChange(props.currentPage + 1)
    };

    const onPrevious = () => {
        if(props.currentPage > 1)
            props.onPageChange(props.currentPage - 1)

    };

    const changePage = (page: number) => {
        props.onPageChange(page)
    }

    return (
        <div
            className='PaginationContainer'
        >
            <Button icon={<CaretLeft24Regular />} onClick={onPrevious}></Button>
            {paginationRange && paginationRange.map((pageNumber, index) => {
                if (pageNumber === DOTS) {
                    return <Badge className="Badge" appearance="ghost" key={index}>...</Badge>;
                }
                const appearance = pageNumber === props.currentPage? 'filled': 'ghost'
                return (
                    <Badge appearance={appearance} className="Badge" size="extra-large" key={index} onClick={() => changePage(pageNumber)}>
                        {pageNumber}
                    </Badge>
                );
            })}
            <Button icon={<CaretRight24Regular />} onClick={onNext}></Button>

        </div>
    );
};
