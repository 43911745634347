export const ERROR_JOBS = "Error Jobs";
export const ERROR_RECORDS = "Error Records";
export const WATING_JOBS = "Waiting Jobs";
export const WATING_RECORDS = "Waiting Records";

export const ERROR_TYPE = "ERROR"
export const WAITING_TYPE = "WAITING"
export const UNPROCESSED_TYPE = "UNPROCESSED"
export const EDITING_TYPE = "EDITING"

export const CANCEL_ACTION = "cancel"
export const SUBMIT_ACTION = "submit"
export const VALIDATE_ACTION = "validate"
export const INVALIDATE_ACTION = "invalidate"

export const ROWS_PER_PAGE = [
  10,30,50,100
]

export const PAGINTATION_SIBLING_COUNT = 2

