import { FC, useEffect, useState } from 'react';
import './TodoTabsView.scss';
import TodoTabFilter from '../../components/TodoTabFilter/TodoTabFilter';
import TodoRecords from '../../components/TodoRecords/TodoRecords';
import { IRecord } from '../../interfaces/IRecord';
import  { getPaginationFromResult } from '../../services/JobsService';
import { Mapper } from '../../utils/mapper';
import RecordService from '../../services/RecordService';
import { ERROR_RECORDS, ERROR_TYPE, ROWS_PER_PAGE, WAITING_TYPE, WATING_RECORDS } from '../../utils/constants';
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, AccordionToggleEventHandler, SelectTabData, SelectTabEvent, Spinner, Tab, TabList } from '@fluentui/react-components';
import { IPagintatedData } from '../../interfaces/IPaginatedData';
import { ApiResult } from '../../utils/helpers';
import { IPageConfig } from '../../interfaces/IPageConfig';
import ToolbarApp from '../../components/ToolbarApp/ToolbarApp';
import { IFilters } from '../../interfaces/IFilters';
import { Add20Filled, Subtract20Filled } from "@fluentui/react-icons";
import { IRecordsData } from '../../interfaces/IRecordsData';

interface TodoTabsViewProps { }

const TodoTabsView: FC<TodoTabsViewProps> = () => {
	const [paginatedRecords, setPaginatedRecords] = useState<IPagintatedData | null>(null)
	const [selectedTab, setSelectedTab] = useState<string>(ERROR_RECORDS)
	const [selectedPage, setSelectedPage] = useState<number>(1)
	const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0])
	const [appliedFilters, setAppliedFilters] = useState()
	const [loadingRecords, setLoadingRecords] = useState<boolean>()
	const [openItem, setOpenItems] = useState(false);

	const pageConfigInitial = {
		limit: ROWS_PER_PAGE[0],
		offset: 0
	}

	useEffect(() => {
		retrieveInfoByTab("")
	}, [])

	const retrieveRecords = (pageConfig: IPageConfig, type?: "ERROR" | "WAITING", filters?: any) => {
		setLoadingRecords(true)
		RecordService.getAll(pageConfig, type, filters).then(
			(response: ApiResult<Array<IRecord>>) => {
				const paginatedResults = getPaginationFromResult(response, pageConfig)
				const records = Mapper.mapToRecordData(paginatedResults.data as Array<IRecord>);
				setPaginatedRecords({
					...paginatedResults,
					data: records
				} as IPagintatedData)
				setLoadingRecords(false)
			}
		)
	}

	const onTabSelected = (event: SelectTabEvent, data: SelectTabData) => {
		setSelectedTab(data.value as string)
		onPageChange(1, data.value as string)
	};

	const retrieveInfoByTab = (selectedTab: string, pageConfig?: IPageConfig, filters?: IFilters) => {
		const pageConf = pageConfig?? pageConfigInitial
		switch (selectedTab) {
			case WATING_RECORDS:
				retrieveRecords(pageConf, WAITING_TYPE, {...filters, workTemplate: 'validation'})
				break;
			default:
				retrieveRecords(pageConf, ERROR_TYPE, {...filters, jobStatus: ERROR_TYPE})
				break;
		}
	}

	const onApplyFilters = (filters: any) => {
		const pageConf = {
			limit: rowsPerPage,
			offset: 0
		}
		setSelectedPage(1)
		retrieveInfoByTab(selectedTab, pageConf, filters)
		setAppliedFilters(filters)
	}

	const onPageChange = (page: number, tab: string) => {
		const pageConf = {
			limit: rowsPerPage,
			offset: rowsPerPage * (page - 1)
		}
		setSelectedPage(page);
		retrieveInfoByTab(tab, pageConf, appliedFilters);
	}

	const onAmountPageChange = (amount: number) => {
		const pageConf = {
			limit: amount,
			offset: 0
		}
		setSelectedPage(1)
		setRowsPerPage(amount)
		retrieveInfoByTab(selectedTab, pageConf, appliedFilters)
	}

	function RecordTable({paginatedRecords}: any) {
		if(loadingRecords) return <Spinner/>;

		return <TodoRecords
			data={paginatedRecords?.data as Array<IRecordsData>?? []}
			allRecordCount={paginatedRecords?.totalPages?? 0}
			rowPerPage={paginatedRecords?.rowsPerPage?? 10}
			onPageChange={onPageChange}
			onAmountPageChange={onAmountPageChange}
			currentPage={selectedPage}
			selectedTab={selectedTab}
		/>
	}

	function changeIcon() {
		setOpenItems(!openItem)
	}

	return (
		<div className="TodoTabsView" data-testid="TodoTabsView">
			<ToolbarApp/>

			<Accordion collapsible >
				<AccordionItem value={1} >
					<div className='AccordionHeaderContainer'>
						<AccordionHeader onClick={changeIcon}
							expandIcon={openItem  ? <Subtract20Filled /> : <Add20Filled />}
						>
							<h3>Filters</h3>
						</AccordionHeader>
					</div>
					<AccordionPanel>
						<div className="filterContainer">
							<TodoTabFilter onApplyFilters={onApplyFilters} />
						</div>
					</AccordionPanel>
				</AccordionItem>
			</Accordion>

			<div className="tabsContainer">
				<div className="TodoTabsComponents" data-testid="TodoTabsComponents">
					<TabList defaultSelectedValue="tab2" onTabSelect={onTabSelected} selectedValue={selectedTab}>
						<Tab value={ERROR_RECORDS}>Error Records</Tab>
						<Tab value={WATING_RECORDS}>Waiting Records</Tab>
					</TabList>
					<RecordTable paginatedRecords={paginatedRecords}/>
				</div>
			</div>
		</div>
	)
};

export default TodoTabsView;

