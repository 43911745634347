import React, { FC, useContext, useState } from 'react';
import './AuthPage.scss';
import { AuthContext } from '../../contexts/AuthContext';
import { Button, Field, Input } from '@fluentui/react-components';
import { Link, useNavigate } from 'react-router-dom';

interface AuthPageProps { }

const AuthPage: FC<AuthPageProps> = () => {
	const [apiToken, setApiToken] = useState('')
	const { login } = useContext(AuthContext);
	const navigate = useNavigate()
	const [showEerror, setShowError] = useState(false);
	const onLogin = () => {
		login(apiToken).then(response => {
			if(response)
				navigate('/')
			else
				setShowError(true)
		})
	}

	const onChangeApiToken = (event: any, data: any) => {
		setApiToken(data.value)
	}

	return (
		<div className="AuthPage" data-testid="AuthPage">
			<h3>Use your API Token from IMaestro here</h3>
			<Field label="Api Token">
				<Input value={apiToken} onChange={onChangeApiToken}></Input>
				{showEerror && <span className='InvalidToken'>Invalid token. Check your API token from IMaestro and try again</span>}
			</Field>
			<Button className='LoginButton' appearance='primary' onClick={onLogin}>Login</Button>
		</div>
	)
};

export default AuthPage;
